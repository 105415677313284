<template>
    <div class="codics-factory-list-body">
        <canvas id="canvas"></canvas>
        <AdminHeader></AdminHeader>
        <div class="codics-factory-list-content">
            <div class="codics-factory-list-content-card" v-for="(item, index) in modelData" :key="index">
                <el-image :src="'https://cdn.bosscaigou.com' + item.icon" fit="cover"
                    class="codics-factory-list-content-card-img"></el-image>
                <el-image class="codics-factory-list-content-card-qr"
                    src="https://cdn.codics.cn/huayuan/img/yasuo_PicsArt_06-12-10.57.27.webp" fit="fill"
                    style="position: absolute;left: 0;top: 0;height: 50px;width: 50px;opacity: .9;"></el-image>
                <div class="codics-factory-list-content-card-vip">
                    <svg class="icon" style="margin-top: 6px;" aria-hidden="true">
                        <use xlink:href="#icon-a-puka-huangguan2"></use>
                    </svg>
                    <div class="codics-factory-list-content-card-vip-txt">{{ item.title }}</div>
                </div>
                <div class="codics-factory-list-content-card-book" v-show="false">
                    <div class="codics-factory-list-content-card-book-page" v-for="(item2, index2) in 7" :key="index2"
                        :style="{ 'max-height': '160px', 'position': index2 == 0 ? 'relative' : 'absolute', 'transition-duration': (index2 + 1) * .4 + 's', backgroundColor: index2 == 0 ? '#FFF !important' : 'unset !important', 'top': '0', 'height': index2 == 0 ? 'auto' : '100%', 'box-sizing': 'border-box' }">
                        <el-image v-show="index2 == 0"
                            :src="'https://cdn.bosscaigou.com' + (item.logo == '' ? '/huayuan/img/yasuo_icon.webp' : item.logo)"
                            style="backface-visibility: hidden !important;z-index: 111;height:auto;display: block;"
                            fit="cover"></el-image>
                    </div>
                </div>
                <el-image class="codics-factory-list-content-card-qr-img"
                    style="position: absolute;margin: auto;height: 100px;width: 100px;top: 0;left: 0;right: 0;bottom: 0;border: 1px solid #DDD;z-index: 1110;transition: .4s;transform: scale(.6);opacity: 0;"
                    :src="item.qrcode" fit="fill"></el-image>
                <el-button class="codics-factory-list-content-card-see" type="warning" style="transition: .4s;"
                    @click="handleClick(item)">{{ item.pass != '' ? '点击预览' :
                (item.modelMember == null ? '点击申请' :
                    (item.modelMember.state == 0 ? '申请中' : (item.modelMember.state == 1 &&
                        !csTime(item.modelMember.ctime)) ? '点击申请' : '点击预览'))
                    }}</el-button>
                <!-- <div class="codics-factory-list-content-card-bottom">
                    <div class="iconfont codics-factory-list-content-card-bottom-txt">&#xe879;{{ ' ' + 123 }}</div>
                    <div class="iconfont codics-factory-list-content-card-bottom-txt">&#xe7c0;{{ ' ' + 99 }}</div>
                    <div class="iconfont codics-factory-list-content-card-bottom-txt" style="float: left;">&#xe873;
                    </div>
                </div> -->
            </div>
        </div>
        <el-button class="codics-factory-list-body-add" type="warning" round plain
            @click="projectState = true">添加申请</el-button>
        <el-dialog title="添加申请" :visible.sync="projectState" width="80%">
            <el-form :model="addForm">
                <el-form-item label="选择项目" :label-width="formLabelWidth">
                    <el-select v-model="addForm.mid" placeholder="请选择申请的项目">
                        <el-option v-for="(item, index) in modelData" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="projectState = false">取 消</el-button>
                <el-button type="primary" @click="addMemberFuc(); dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <div class="codics-factory-list-body-intro" v-show="introState">
            <div class="codics-factory-list-body-intro-box">
                <el-carousel height="620px" class="codics-factory-list-body-intro-box-lunbo" :autoplay="false"
                    ref="ydCarousel">
                    <el-carousel-item v-for="item in lunboData" :key="item">
                        <el-image :src="item" fit="fill"></el-image>
                    </el-carousel-item>
                </el-carousel>
                <div class="codics-factory-list-body-intro-box-line">
                    <div
                        style="height: 120px;width: 100%;position: relative;float: left;line-height: 120px;text-align: center;font-size: 28px;color: #FFA322;">
                        流程引导</div>
                    <el-button class="codics-factory-list-body-intro-box-line-btn" style="margin-top: 12px;"
                        type="warning"><span class="iconfont">&#xe616;</span>&nbsp;&nbsp;&nbsp;注册成功</el-button>
                    <el-image class="codics-factory-list-body-intro-box-line-jt"
                        src="https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2030%403x.webp"
                        fit="contain"></el-image>
                    <el-button class="codics-factory-list-body-intro-box-line-btn" style="margin-top: 15px;"
                        :type="stepIndex >= 1 ? 'warning' : 'info'" @click="stepIndex = 1; nextStep(stepIndex);"><span
                            class="iconfont">&#xe689;</span>&nbsp;&nbsp;&nbsp;添加管理微信:18661925657</el-button>
                    <el-image class="codics-factory-list-body-intro-box-line-jt"
                        src="https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2030%403x.webp"
                        fit="contain"></el-image>
                    <el-button class="codics-factory-list-body-intro-box-line-btn" style="margin-top: 15px;"
                        :type="stepIndex >= 2 ? 'warning' : 'info'" @click="stepIndex = 2; nextStep(stepIndex);"><span
                            class="iconfont">&#xe621;</span>&nbsp;&nbsp;&nbsp;申请预览模型并告知管理</el-button>
                    <el-image class="codics-factory-list-body-intro-box-line-jt"
                        src="https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2030%403x.webp"
                        fit="contain"></el-image>
                    <el-button class="codics-factory-list-body-intro-box-line-btn" style="margin-top: 15px;"
                        :type="stepIndex >= 3 ? 'warning' : 'info'" @click="stepIndex = 3; nextStep(stepIndex);"><span
                            class="iconfont">&#xe65f;</span>&nbsp;&nbsp;&nbsp;审核通过，预览模型</el-button>
                    <div style="height: 0;width: 100%;position: relative;float: left;"></div>
                    <el-button class="codics-factory-list-body-intro-box-line-btn" style="margin-top: 45px;"
                        type="warning" @click="stepIndex++; nextStep(stepIndex);">{{ stepIndex >= 3 ? '完成引导，开始使用' :
                '下一步' }}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#canvas {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 0;
}

.codics-factory-list-body-intro {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    backdrop-filter: blur(4px);
    z-index: 100;
}

.codics-factory-list-body-intro-box {
    height: 620px;
    width: 1220px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF;
}

.codics-factory-list-body-intro-box-lunbo {
    height: 100%;
    width: 800px;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-factory-list-body-intro-box-line {
    height: 100%;
    width: 420px;
    position: relative;
    float: left;
}

.codics-factory-list-body-intro-box-line-btn {
    position: relative !important;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

.codics-factory-list-body-intro-box-line-jt {
    height: 30px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    margin-top: 15px;
}
</style>

<script>
import { mapState } from 'vuex';
import AdminHeader from '@/components/AdminHeader';
import isolde from 'isolde';
import 'isolde/dist/isolde.min.css';
import { listModel2, addMember, userInfos } from '@/api';
import QRCode from 'qrcode';
import { add } from 'three/examples/jsm/libs/tween.module';
import { jumpTo, copyTxt } from '@/utils/myutils';
import { getCookie, setCookie } from '@/utils/mycookie';
export default {
    data() {
        return {
            introState: getCookie("threejs-factory-intro") == null,
            addForm: {
                mid: null
            },
            stepIndex: 0,
            projectState: false,
            formLabelWidth: '120px',
            modelData: [
            ],
            lunboData: [
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/%E8%B5%84%E6%BA%90%2037%403x.png',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/%E8%B5%84%E6%BA%90%2039%403x.png',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/%E8%B5%84%E6%BA%90%2040%403x.png',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/%E8%B5%84%E6%BA%90%2038%403x.png'
            ]
        }
    },
    mounted() {
        if (this.userInfo == null) {
            this.jumpToPage("工厂主页", {}, {});
        }
        this.listModel2Fuc();
        this.initBack();
    },
    methods: {
        jumpToPage(n, p, q) {
            jumpTo(this, n, p, q);
        },
        initBack() {

            var canvas = document.getElementById('canvas'),

                ctx = canvas.getContext('2d'),

                w = canvas.width = window.innerWidth,

                h = canvas.height = window.innerHeight,

                hue = 217,

                stars = [],

                count = 0,

                maxStars = 2000;//星星数量

            var canvas2 = document.createElement('canvas'),

                ctx2 = canvas2.getContext('2d');

            canvas2.width = 100;

            canvas2.height = 100;

            var half = canvas2.width / 2,

                gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);

            gradient2.addColorStop(0.025, '#CCC');

            gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');

            gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');

            gradient2.addColorStop(1, 'transparent');



            ctx2.fillStyle = gradient2;

            ctx2.beginPath();

            ctx2.arc(half, half, half, 0, Math.PI * 2);

            ctx2.fill();



            //End cache



            function random(min, max) {

                if (arguments.length < 2) {

                    max = min;

                    min = 0;

                }



                if (min > max) {

                    var hold = max;

                    max = min;

                    min = hold;

                }



                return Math.floor(Math.random() * (max - min + 1)) + min;

            }



            function maxOrbit(x, y) {

                var max = Math.max(x, y),

                    diameter = Math.round(Math.sqrt(max * max + max * max));

                return diameter / 2;

                //星星移动范围，值越大范围越小，

            }



            var Star = function () {



                this.orbitRadius = random(maxOrbit(w, h));

                this.radius = random(60, this.orbitRadius) / 8;

                //星星大小

                this.orbitX = w / 2;

                this.orbitY = h / 2;

                this.timePassed = random(0, maxStars);

                this.speed = random(this.orbitRadius) / 500000;

                //星星移动速度

                this.alpha = random(2, 10) / 50;

                count++;

                stars[count] = this;

            }

            Star.prototype.draw = function () {

                var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,

                    y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,

                    twinkle = random(10);



                if (twinkle === 1 && this.alpha > 0) {

                    this.alpha -= 0.05;

                } else if (twinkle === 2 && this.alpha < 1) {

                    this.alpha += 0.05;

                }



                ctx.globalAlpha = this.alpha;

                ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);

                this.timePassed += this.speed;

            }



            for (var i = 0; i < maxStars; i++) {

                new Star();

            }



            function animation() {

                ctx.globalCompositeOperation = 'source-over';

                ctx.globalAlpha = 0.5; //尾巴

                ctx.fillStyle = 'hsla(' + hue + ', 64%, 6%, 2)';

                ctx.fillRect(0, 0, w, h)



                ctx.globalCompositeOperation = 'lighter';

                for (var i = 1, l = stars.length; i < l; i++) {

                    stars[i].draw();

                    canvas2.style.cssText = "display:none";

                };



                window.requestAnimationFrame(animation);

            }

            animation();
        },
        nextStep(idx) {
            console.log(idx)
            this.$refs.ydCarousel.setActiveItem(idx < 0 ? 0 : (idx > 3 ? 3 : idx));
            switch (idx) {
                case 1:
                    this.$message({
                        message: '管理员微信号复制成功啦！请打开微信添加！',
                        type: 'success'
                    });
                    copyTxt("18661925657");
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    setCookie("threejs-factory-intro", "11");
                    this.introState = false;
                    break;
            }
        },
        csTime(time) {
            return new Date(time) > new Date();
        },
        async creatQrCode(i, QRlink) {   // QRlink是二维码的内容
            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 1,//二维码留白边距
                width: 100,//宽
                height: 100,//高
                text: QRlink,//二维码内容
                color: {
                    dark: "#000",//前景色
                    light: "#fff"//背景色
                }
            };
            await QRCode.toDataURL(QRlink, opts, (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                // console.log(url)
                this.modelData[i]['qrcode'] = url;
            })
        },
        handleClick(item) {

            if (item.pass != '') {

                jumpTo(this, "工厂浏览", { id: item.id }, {});

            } else if (item.modelMember == null) {

                this.addForm.mid = item.id;

                this.projectState = true;

            } else {

                switch (item.modelMember.state) {

                    case 0:

                        this.$message({
                            showClose: true,
                            message: '申请已提交，请耐心等待！',
                            type: 'warning'
                        });

                        break;

                    case 1:

                        if (!this.csTime(item.modelMember.ctime)) {

                            this.addForm.mid = item.id;

                            this.projectState = true;

                            return;

                        }

                        jumpTo(this, "工厂浏览", { id: item.id }, {});

                        break;

                    case 2:

                        this.$message({
                            showClose: true,
                            message: '申请已被拒绝，请重新提交！',
                            type: 'danger'
                        });

                        this.addForm.mid = item.id;

                        this.projectState = true;

                        break;

                }

            }

        },
        async listModel2Fuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listModel2();

            loading.close();

            if (data.code == 200) {

                this.modelData = data.data;

                for (var i in this.modelData) {

                    this.creatQrCode(i, 'https://threejs.katihuo.com/api/show/' + this.modelData[i].id);

                }

            } else {

                this.$message.error(data.message);

            }
        },
        async addMemberFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addMember(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '申请提交成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }
        }
    },
    components: {
        AdminHeader
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
.codics-factory-list-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
}

.codics-factory-list-content {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 7.5px 0 7.5px;
    margin-top: 7.5px;
    overflow-y: scroll;
}

.codics-factory-list-content-card-img {
    height: 100%;
    width: 100%;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-factory-list-content-card {
    position: relative;
    float: left;
    height: 300px;
    width: calc(25% - 15px);
    box-sizing: border-box;
    background: rgba(255, 255, 255, .25) !important;
    backdrop-filter: blur(5px) !important;
    background: linear-gradient(0deg, #fff, #f3f5f8);
    /* border: 2px solid #FFF; */
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    margin: 7.5px;
}

.codics-factory-list-content-card:hover {
    box-shadow: 2px 2px 5px 0 rgba(55, 99, 170, .1);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book {
    left: calc(50% + 60px);
}

/* .codics-factory-list-content-card:hover .codics-factory-list-content-card-see {
    opacity: 1;
} */

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(1) {
    transform: rotateY(-180deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(2) {
    transform: rotateY(-160deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(3) {
    transform: rotateY(-140deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(4) {
    transform: rotateY(-130deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(5) {
    transform: rotateY(-45deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(6) {
    transform: rotateY(-35deg);
}

.codics-factory-list-content-card:hover .codics-factory-list-content-card-book-page:nth-child(7) {
    transform: rotateY(-20deg);
}

.codics-factory-list-content-card-book {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    height: auto;
    /*150px;*/
    width: 120px;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: .4s;
    background-color: transparent;
    box-sizing: border-box;
}

.codics-factory-list-content-card-book-page {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 0 6px 6px 0;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
    /* background-image: -webkit-linear-gradient(rgba(200, 200, 200, 0.1) 0.1em, transparent 0.1em), -webkit-linear-gradient(left, #f5f3f1 0%, #ffffff 100%); */
    background-size: 100% 0.6em;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2), inset -1px 0px 1px rgba(150, 150, 150, 0.3);
    background: -webkit-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: -moz-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: -ms-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: linear-gradient(left, #FFF 0%, #fffbf6 100%);
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.codics-factory-list-content-card-book-page:nth-child(1) {
    backface-visibility: hidden;
}

/* .codics-factory-list-content-card-book-page:nth-child(1)::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 1;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2), inset -1px 0px 1px rgba(150, 150, 150, 0.3);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
} */

.codics-factory-list-content-card-vip {
    height: 30px;
    width: fit-content;
    position: absolute;
    top: 5px;
    right: 5px;
    /* border: 1px solid #FFF; */
    background: rgba(0, 0, 0, 0);
    /* border-radius: 2px; */
    box-sizing: border-box;
    text-align: center;
    padding: 0 10px 0 10px;
}

.codics-factory-list-content-card-vip-txt {
    line-height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    color: #FFF;
    font-weight: 500;
    position: relative;
    float: right;
    margin-left: 6px;
}

.codics-factory-list-content-card-see {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 45%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1111;
}

.codics-factory-list-more-btn-box {
    position: relative;
    height: fit-content;
    width: 100%;
    float: left;
    margin-top: 100px;
    box-sizing: border-box;
}

.codics-factory-list-more-btn {
    position: relative;
    height: fit-content;
    width: 90%;
    max-width: 500px;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
}

.codics-factory-list-content-card-bottom {
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

}

.codics-factory-list-content-card-bottom-txt {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    padding: 0 10px 0 10px;
}

.codics-factory-list-content-card-qr:hover~.codics-factory-list-content-card-qr-img {
    transform: scale(1) !important;
    opacity: 1 !important;
}

/* .codics-factory-list-content-card-qr:hover~.codics-factory-list-content-card-see {
    opacity: 0 !important;
    transform: scale(.6) !important;
} */

.codics-factory-list-body-add {
    position: fixed !important;
    bottom: 35px;
    right: 35px;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, .2);
}

@media (max-width:768px) {
    .codics-factory-list-content-card {
        width: calc(100% - 20px) !important;
    }
}
</style>