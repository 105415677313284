import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home';
import Show from '@/pages/Show';
import List from '@/pages/List';
import View from '@/pages/View';
import Model from '@/pages/Admin/Model';
import ModelUpload from '@/pages/Admin/Model/Upload';
import Login from '@/pages/Login';
import Admin from '@/pages/Admin';
import AdminUser from '@/pages/Admin/User';
import AdminUserApply from '@/pages/Admin/User/Apply';
import AdminUserStaff from '@/pages/Admin/User/Staff';
import AdminUserMenu from '@/pages/Admin/User/Menu';
Vue.use(VueRouter);
export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: '工厂主页',
            path: '/',
            component: Home,
            meta: {
                metaInfo: {
                    title: '邦邦工厂-工厂主页',
                    keywords: '1',
                    description: '1'
                }
            }
        },
        {
            name: '预览列表',
            path: '/list',
            component: List,
            meta: {
                metaInfo: {
                    title: '邦邦工厂-预览列表',
                    keywords: '',
                    description: ''
                }
            }
        },
        {
            name: '地球主页',
            path: '/ceshi',
            component: Home,
            meta: {
                metaInfo: {
                    title: '邦邦工厂-地球主页',
                    keywords: '',
                    description: ''
                }
            }
        },
        {
            name: '工厂浏览',
            path: '/show/:id',
            component: View,
            meta: {
                metaInfo: {
                    title: '邦邦工厂-工厂浏览',
                    keywords: '',
                    description: ''
                }
            }
        },
        {
            name: '后端登录',
            path: '/login',
            component: Login,
            meta: {
                metaInfo: {
                    title: '邦邦工厂-后端登录',
                    keywords: '',
                    description: ''
                }
            }
        },
        {
            name: '后台首页',
            path: '/admin',
            component: Admin,
            redirect: '/admin/home',
            meta: {
                metaInfo: {
                    title: '邦邦工厂-后台首页',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            },
            children: [
                {
                    name: '后台首页',
                    path: 'home',
                    component: ModelUpload,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-后台首页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '模型管理',
                    path: 'model',
                    component: Model,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-模型管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '模型上传',
                    path: 'upload',
                    component: ModelUpload,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-模型上传',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '用户管理',
                    path: 'user',
                    component: AdminUser,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-用户管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '员工管理',
                    path: 'staff',
                    component: AdminUserStaff,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-员工管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '菜单管理',
                    path: 'menu',
                    component: AdminUserMenu,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-菜单管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户管理',
                    path: 'apply',
                    component: AdminUserApply,
                    meta: {
                        metaInfo: {
                            title: '邦邦工厂-商户管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    path: '/admin*',
                    redirect: '/admin/home'
                }
            ]
        },
        {
            path: '*',
            redirect: '/'
        },
    ],
});