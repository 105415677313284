<template>
    <div class="codics-factory-admin-model-upload-body">
        <el-switch class="codics-factory-admin-model-switch" style="left: -200px;" v-model="zipState" active-text="压缩"
            inactive-text="不压缩">
        </el-switch>
        <el-switch class="codics-factory-admin-model-switch" style="right: -200px;" v-model="passState" active-text="加密"
            inactive-text="不加密">
        </el-switch>
        <el-upload class="codics-factory-admin-model-upload" :http-request="uploadFile" drag :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/" accept=".glb,.gltf">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传glb/gltf模型文件，且不超过100MB</div>
        </el-upload>
        <!-- 添加模型 -->
        <el-dialog title="添加模型" :visible.sync="addModelVisible" width="80%">
            <el-form :model="addForm">
                <el-form-item label="模型标题" :label-width="formLabelWidth">
                    <el-input v-model="addForm.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="模型密码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="页面水印" :label-width="formLabelWidth">
                    <el-input v-model="addForm.wmark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="加载Logo" :label-width="formLabelWidth">
                    <el-upload action="https://cdn.bosscaigou.com/" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" class="codics-admin-model-logo-upload"
                        :http-request="uploadFile2" :on-remove="handleRemove2"
                        :style="{ '--himgSize': addForm.logo == '' ? 'inline-block' : 'none' }" ref="fmUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="背景音乐" :label-width="formLabelWidth">
                    <el-button type="primary">点击上传</el-button>
                </el-form-item>
                <el-form-item label="模型状态" :label-width="formLabelWidth">
                    <el-select v-model="addForm.state" placeholder="请选择模型状态">
                        <el-option label="有预览可申请" value="0"></el-option>
                        <el-option label="无预览可申请" value="1"></el-option>
                        <el-option label="无预览无申请" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分享标题" :label-width="formLabelWidth">
                    <el-input v-model="addForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分享介绍" :label-width="formLabelWidth">
                    <el-input v-model="addForm.detail" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分享LOGO" :label-width="formLabelWidth">
                    <el-upload action="https://cdn.bosscaigou.com/" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" class="codics-admin-model-icon-upload"
                        :http-request="uploadFile3" :on-remove="handleRemove3"
                        :style="{ '--himgSize': addForm.icon == '' ? 'inline-block' : 'none' }" ref="fmUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addModelVisible = false">取 消</el-button>
                <el-button type="primary" @click="addModelFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addModel, getQiNiuToken } from '@/api';
import * as qiniu from 'qiniu-js';
export default {
    data() {
        return {
            formLabelWidth: '120px',
            zipState: false,
            zipNum: 10,
            passState: false,
            qiNiuInfo: {
                token: null
            },
            addModelVisible: false,
            addForm: {
                title: '',
                url: '',
                pass: '',
                wmark: '',
                logo: '',
                bgm: '',
                state: 0,
                name: "",
                detail: '',
                icon: ''
            }
        }
    },
    mounted() {
        this.getTokenFuc();
    },
    methods: {
        // 上传方法
        uploadFile(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.addForm.url = 'huayuan/model/' + uploadInfo.file.name.replace(',', '');

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.url = '/' + res.key;
                    _that.addModelVisible = true;
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/model/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 上传方法
        uploadFile2(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.logo = '/' + res.key;
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 上传方法
        uploadFile3(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.icon = '/' + res.key;
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        async addModelFuc() {

            if (this.addForm.title == '' || this.addForm.url == '') {

                this.$message.error('模型信息不能为空！');

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.addForm.pass = this.addForm.pass == '' ? '' : hex_md5(this.addForm.pass);

            let data = await addModel(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加活动成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        // 图片点击预览
        handlePictureCardPreview(file) {

        },
        handleRemove() {
            this.addForm.url = '';
            this.updateForm.url = '';
        },
        handleRemove2() {
            this.addForm.logo = '';
            this.updateForm.logo = '';
        },
        handleRemove3() {
            this.addForm.icon = '';
            this.updateForm.icon = '';
        },
    }
}
</script>

<style>
.codics-factory-admin-model-upload-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

.codics-factory-admin-model-switch {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    top: -290px;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-factory-admin-model-upload {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-admin-model-logo-upload .el-upload {
    display: var(--himgSize);
}

.codics-admin-model-icon-upload .el-upload {
    display: var(--himgSize);
}
</style>