<template>
    <div class="earth_container" ref="earthContainer">
        <div class="codics-huayuan-threejs-factory-home-top">
            <img class="codics-huayuan-threejs-factory-home-top-logo"
                src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%A5%A5%E7%BA%B3%E5%B0%94logo%281%29.webp"
                fit="fill">
            <!-- <router-link class="ccodics-huayuan-threejs-factory-home-top-txt iconfont" to="/"
                v-for="(item, index) in topMenu" :key="index"><span v-html="item.content"></span>
                <div class="codics-huayuan-threejs-factory-home-sub-bar" v-if="item.state == 1">
                    <div class="codics-huayuan-threejs-factory-home-sub-bar-title">{{ item.content }}</div>
                    <div class="codics-huayuan-threejs-factory-home-sub-left-menu-box">
                        <div class="codics-huayuan-threejs-factory-home-sub-left-menu-item"
                            v-for="(item2, index2) in item.data" :key="index2" @mouseenter="item.nowIndex = index2"
                            :style="{ background: item.nowIndex == index2 ? 'var(--shadeColor)' : '' }">{{ item2.title
                            }}<div class="codics-huayuan-threejs-factory-home-sub-left-menu-item-enter iconfont">
                                &#xe844;</div>
                        </div>
                    </div>
                    <div class="codics-huayuan-threejs-factory-home-sub-right-app-box">
                        <div class="codics-huayuan-threejs-factory-home-sub-right-app-item" @click="subGo(item3);"
                            :key="index3" v-for="(item3, index3) in item.data[item.nowIndex].data">
                            <img class="codics-huayuan-threejs-factory-home-sub-right-app-item-img" :src="item3.img">
                            <div class="codics-huayuan-threejs-factory-home-sub-right-app-item-name">{{ item3.name }}
                            </div>
                            <div class="codics-huayuan-threejs-factory-home-sub-right-app-item-desc">{{ item3.desc }}
                            </div>
                        </div>
                    </div>
                </div>
            </router-link> -->
        </div>
        <div
            :class="{ 'codics-huayuan-threejs-factory-home-login-box': true, 'codics-huayuan-threejs-factory-home-login-box-login-state': loginState }">
            <div class="codics-huayuan-threejs-factory-home-login-txt-view">
                <div class="codics-huayuan-threejs-factory-home-login-txt-tag">
                    做&nbsp;&nbsp;&nbsp;强&nbsp;&nbsp;&nbsp;民&nbsp;&nbsp;&nbsp;族&nbsp;&nbsp;&nbsp;品&nbsp;&nbsp;&nbsp;牌&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;成&nbsp;&nbsp;&nbsp;就&nbsp;&nbsp;&nbsp;全&nbsp;&nbsp;&nbsp;球&nbsp;&nbsp;&nbsp;客&nbsp;&nbsp;&nbsp;户
                </div>
                <el-button class="codics-huayuan-threejs-factory-home-login-txt-tag-btn" type="warning"
                    @click="changeFuc()">{{ userInfo == null ? '登 录 / 注 册' : '去 查 看' }}</el-button>
            </div>
            <div class="codics-huayuan-threejs-factory-home-login-login-box">
                <div class="codics-huayuan-threejs-factory-home-login-carousel-box">
                    <el-carousel class="codics-huayuan-threejs-factory-home-login-carousel" height="362px" width="100%">
                        <el-carousel-item class="codics-huayuan-threejs-factory-home-login-carousel-item"
                            v-for="item in lunboData" :key="item">
                            <el-image :src="item" style="height: 100%;width: 100%;" fit="cover"></el-image>
                            <img class="codics-huayuan-threejs-factory-home-login-carousel-item-logo"
                                src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%A5%A5%E7%BA%B3%E5%B0%94logo%281%29.webp"
                                fit="fill">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="codics-huayuan-threejs-factory-home-login-view">
                    <div class="codics-huayuan-threejs-factory-home-login-login-box-close iconfont"
                        @click="loginState = !loginState;">&#xe84a;</div>
                    <div class="codics-huayuan-threejs-factory-home-login-title-box">
                        <div class="codics-huayuan-threejs-factory-home-login-title"
                            v-for="(item, index) in ['账号登录', '账号注册']" :key="index"
                            :style="{ '--idxState': idxState == index ? '2px solid #333' : 'unset', '--fontCol': idxState == index ? '#333' : '#888' }"
                            @click="idxState = index">{{ item }}</div>
                    </div>
                    <el-input v-show="idxState == 1" v-model="regInfo.a" maxlength="11" type="number"
                        placeholder="请输入手机号作为登录账号..." style="margin-bottom: 14px;"></el-input>
                    <el-input v-show="idxState == 1" v-model="regInfo.p" maxlength="22" placeholder="请输入账号的密码..."
                        :show-password="true" style="margin-bottom: 14px;" clearable></el-input>
                    <el-input v-show="idxState == 1" v-model="regInfo.c" maxlength="22" placeholder="请输入公司名称..."
                        style="margin-bottom: 14px;" clearable></el-input>
                    <el-input v-show="idxState == 1" v-model="regInfo.n" maxlength="22" placeholder="请输入您的姓名..."
                        style="margin-bottom: 14px;" clearable></el-input>
                    <el-button v-show="idxState == 1" class="codics-huayuan-threejs-factory-home-login-input-card-login"
                        plain type="warning" @click="userRegisterFuc">注册账号</el-button>
                    <el-image v-if="idxState == 2" class="codics-huayuan-threejs-factory-home-login-qrcode" :src="qrImg"
                        fit="fill"></el-image>
                    <div v-show="idxState == 2" class="codics-huayuan-threejs-factory-home-login-qrcode-tip"
                        v-html="qrTxt">
                    </div>
                    <el-input v-show="idxState == 0" v-model="logInfo.a" maxlength="22" placeholder="请输入账号/邮箱/手机号..."
                        style="margin-bottom: 15px;" clearable></el-input>
                    <el-input v-show="idxState == 0" v-model="logInfo.p" maxlength="22" placeholder="请输入账号相应的密码..."
                        :show-password="true" style="margin-bottom: 15px;" clearable></el-input>
                    <el-input v-show="idxState == 0" class="codics-huayuan-threejs-factory-home-login-verify-code"
                        placeholder="请输入验证码" maxlength="4" v-model="logInfo.v" clearable>
                        <template slot="append">
                            <el-image class="codics-huayuan-threejs-factory-home-login-input-card-code" :src="verifyImg"
                                fit="fill" @click="changeVerifyFuc">
                                <div slot="error" class="image-slot"
                                    style="height:fit-content;width: fit-content;position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 0;"
                                    @click="changeVerifyFuc">
                                    <i class="el-icon-loading" v-show="verifyState"></i>
                                </div>
                            </el-image>
                            <div style="height: 100%;width: 100%;position: absolute;line-height: 40px;text-align: center;font-size: 13px;left: 0;top: 0;user-select: none;cursor: pointer;"
                                v-show="!verifyState" @click="changeVerifyFuc(); verifyState = true;">加载验证码</div>
                        </template>
                    </el-input>
                    <div v-show="idxState == 0" class="codics-huayuan-threejs-factory-home-login-line">
                        <el-checkbox v-model="logCheck">7天免登录</el-checkbox>
                        <a class="codics-huayuan-threejs-factory-home-login-line-pass"
                            href="https://qm.qq.com/q/VPQBQGwUUI">忘记密码？</a>
                    </div>
                    <el-button v-show="idxState == 0" class="codics-huayuan-threejs-factory-home-login-input-card-login"
                        style="width: calc(50% - 7px);" plain type="warning" @click="userLoginFuc">账号登录</el-button>
                    <el-button v-show="idxState == 0" class="codics-huayuan-threejs-factory-home-login-input-card-login"
                        style="width: calc(50% - 7px);" plain type="success" @click="idxState = 1;">注册账号</el-button>
                </div>
            </div>
        </div>
        <div class="codics-huayuan-threejs-factory-home-bottom">© 2024 (threejs.katihuo.com)
            <a href="#">山东华远创智信息技术有限公司</a>&nbsp;版权所有，保留所有权利
            <a href="https://beian.miit.gov.cn/" style="text-decoration: none;">鲁ICP备2023021315号-3</a>&nbsp;
            <a href="tel: 4006-315-789" style="text-decoration: none;">客服电话：4006-315-789</a>
        </div>
    </div>
</template>

<style>
.codics-huayuan-threejs-factory-home-login-carousel-item-logo {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 20px;
    top: 20px;
    user-select: none;
    -webkit-user-drag: none;
}

.codics-huayuan-threejs-factory-home-login-login-box-close {
    height: 50px;
    width: 50px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    color: #333;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}

.codics-huayuan-threejs-factory-home-login-box {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    transition: .6s;
    transform-style: preserve-3d;
    pointer-events: none;
}

.codics-huayuan-threejs-factory-home-login-box-login-state {
    transform: rotateY(180deg) !important;
}

.codics-huayuan-threejs-factory-home-login-box-login-state2 {
    transform: rotateY(0deg) !important;
}

.codics-huayuan-threejs-factory-home-login-txt-view {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    transition: .4s;
    backface-visibility: hidden;
    pointer-events: all;
}

.codics-huayuan-threejs-factory-home-login-txt-tag {
    height: 100px;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 100px;
    text-align: center;
    font-size: 38px;
    color: #FFF;
    font-weight: 600;
    box-sizing: border-box;
    z-index: 100;
    text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.95);
}

.codics-huayuan-threejs-factory-home-login-txt-tag::after {
    content: 'NATIONAL BRAND · GLOBAL CUSTOMERS';
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 50px;
    right: 0;
    bottom: -50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #FFF;
    font-weight: 300;
    letter-spacing: 10px;
    box-sizing: border-box;
}

.codics-huayuan-threejs-factory-home-login-txt-tag-btn {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: -290px;
    z-index: 100;
}

/* .codics-huayuan-threejs-factory-home-login-view {
    height: 260px;
    width: 400px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .1);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, .22);
    z-index: -30;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    transition: .4s;
    backface-visibility: hidden;
} */
</style>


<script>
import { mapState } from 'vuex';
import { changeVerify, userLogin, userRegister } from "@/api";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { setCookieWithExp } from "@/utils/mycookie";
import { jumpTo } from "@/utils/myutils";
// 初始化屏幕宽度
let w = window.innerWidth;
// 初始化屏幕高度
let h = window.innerHeight;
// 初始化Threejs场景
const scene = new THREE.Scene();
// 初始化镜头
const camera = new THREE.PerspectiveCamera(60, w / h, 0.001, 1000);
// 初始化场景渲染器
const renderer = new THREE.WebGLRenderer({
    antialias: true,
    // alpha: true
});
// 初始化场景控制器：轨道控制器
const controls = new OrbitControls(camera, renderer.domElement);
// 记录当前时间
let clock = new THREE.Clock();

export default {
    data() {
        return {
            lunboData: [
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2050%403x.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2051%403x.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2049%403x.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2049%403x%20%281%29.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_%E8%B5%84%E6%BA%90%2045%403x.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_Layer%200%403x.webp',
                'https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/yasuo_Layer%200%20%282%29%403x.webp'
            ],
            logCheck: false,
            idxState: 0,
            qrTxt: '',
            meshObj: null,
            materialObj: null,
            loginState: false,
            verifyImg: '',
            verifyState: false,
            logInfo: {
                a: '',
                p: '',
                v: '',
                e: 0
            },
            regInfo: {
                a: '',
                p: '',
                c: '',
                n: ''
            },
            topMenu: [
                {
                    id: 1,
                    content: '首页',
                    state: 0
                },
                {
                    id: 2,
                    content: '奥纳尔',
                    state: 1,
                    nowIndex: 0,
                    data: [
                        {
                            id: 1,
                            title: '高校服务板块',
                            data: [
                                {
                                    id: 1,
                                    img: 'https://cdn.codics.cn/tmp/%E6%A0%A1%E5%9B%AD%E8%B7%91%E8%85%BF.svg',
                                    name: '校园服务小程序',
                                    desc: '为大学生提供更为便捷、全面的服务，满足他们在学术、社交、生活等方面的各种需求。',
                                    type: 1,
                                    url: 'https://posto.cn/90948201/'
                                },
                                {
                                    id: 2,
                                    img: 'https://cdn.codics.cn/tmp/%E7%A0%94%E7%A9%B6%E7%94%9F.svg',
                                    name: '攀研小程序',
                                    desc: '通过符合人体记忆曲线的记忆训练方法，帮助用户更好更快更全的掌握更多的知识。',
                                    type: 1,
                                    url: 'https://posto.cn/90948202/'
                                },
                                {
                                    id: 3,
                                    img: 'https://cdn.codics.cn/tmp/%E6%88%91%E7%9A%84%E7%AE%80%E5%8E%86.svg',
                                    name: '第一简历',
                                    desc: '按照用户选择的模板，快速的填写简历信息，一键生成导出，求职路上零障碍！',
                                    type: 1,
                                    url: 'https://jl.posto.cn/'
                                }
                            ]
                        },
                        {
                            id: 2,
                            title: '社区服务板块',
                            data: [
                                {
                                    id: 1,
                                    img: 'https://cdn.codics.cn/tmp/%E8%81%8A%E5%A4%A9.svg',
                                    name: '代码有的聊',
                                    desc: '为平台产品的用户以及其他程序员提供一个反馈交流的平台，服务更多有需要的人。'
                                },
                            ]
                        },
                        {
                            id: 3,
                            title: '其他服务板块',
                            data: [
                                {
                                    id: 1,
                                    img: 'https://cdn.codics.cn/tmp/%E6%90%9C%E7%B4%A22.svg',
                                    name: '简单搜索',
                                    desc: '聚合N多搜索引擎，简单快捷，一触即达，快速切换搜索引擎，提高搜索工作效率。'
                                },
                                {
                                    id: 2,
                                    img: 'https://cdn.codics.cn/tmp/%E8%87%AA%E5%88%B6%E5%8D%B0%E7%AB%A0_1706681188530%281%29%20%281%29.svg',
                                    name: '觅荼画框APP',
                                    desc: '百款精美画框，一键试用，智能适应，无损加框，随点随用，一键提升作品气质。'
                                },
                                {
                                    id: 3,
                                    img: 'https://cdn.codics.cn/tmp/favicon.svg',
                                    name: 'CoDics™APP',
                                    desc: '深度优化编辑功能，简化用户操作，给用户丝滑的应用编程体验。'
                                },
                                {
                                    id: 4,
                                    img: 'https://cdn.codics.cn/tmp/API%3Don.svg',
                                    name: '开火API',
                                    desc: '免费的API接口，为了保障接口性能，热搜数据每10分钟刷新一次'
                                },
                                {
                                    id: 5,
                                    img: 'https://cdn.codics.cn/tmp/%E8%8A%9D%E5%A3%AB.svg',
                                    name: '芝士星球',
                                    desc: '一个程序员专用的芝士星球，分享软件开发过程中一些有意思的开发过程及技术细节！',
                                    type: 1,
                                    url: 'https://www.posto.cn/'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    content: '华誉机械',
                    state: 1,
                    nowIndex: 0,
                    data: [
                        {
                            id: 1,
                            title: '文本智能服务',
                            data: []
                        },
                        {
                            id: 2,
                            title: '图像智能处理',
                            data: []
                        },
                        {
                            id: 3,
                            title: '其他类型服务',
                            data: []
                        }
                    ]
                },
                {
                    id: 4,
                    content: '耗邦邦',
                    state: 2
                }
            ]
        }
    },
    mounted() {
        // 加载验证码
        this.changeVerifyFuc();
        // 初始化场景
        this.initThree();

    },
    methods: {
        changeFuc() {
            if (this.userInfo == null) {
                this.loginState = !this.loginState;
            } else {
                jumpTo(this, '预览列表', {}, {});
            }
        },
        async userRegisterFuc() {
            if (this.regInfo.a == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的账号不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.regInfo.a.length != 11) {
                this.$notify({
                    title: '警告',
                    message: '请填写11位数字手机号码！',
                    type: 'warning'
                });
                return;
            }
            if (this.regInfo.p == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的密码不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.regInfo.c == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的公司名称不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.regInfo.n == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的姓名不能为空！',
                    type: 'warning'
                });
                return;
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await userRegister(this.regInfo);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.message,
                    type: 'success'
                });
                setCookieWithExp("user-inf", JSON.stringify(data), 7);
                // 刷新仓库数据
                this.$store.dispatch('login');
                // 登录成功跳转
                jumpTo(this, data.user.member == 0 ? '后台首页' : (data.user.uar != null ? '后台首页' : '预览列表'), {}, {});

            } else this.$message.error(data.message);
        },
        async userLoginFuc() {
            if (this.logInfo.a == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的账号不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.logInfo.p == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的密码不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.logInfo.v == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的验证码不能为空！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.logInfo.e = this.logCheck ? 168 : 2;
            let data = await userLogin(this.logInfo);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.message,
                    type: 'success'
                });
                setCookieWithExp("user-inf", JSON.stringify(data), this.logCheck ? 7 : (1 / 12));
                // 刷新仓库数据
                this.$store.dispatch('login');
                // 登录成功跳转
                console.log(this.lyPath)
                if (this.lyPath != null) {

                    window.location.href = this.lyPath;

                    return;

                }
                jumpTo(this, data.user.member == 0 ? '后台首页' : (data.user.uar != null ? '后台首页' : '预览列表'), {}, {});
            } else this.$message.error(data.message);
        },
        async changeVerifyFuc() {
            let data = await changeVerify();
            if (data.code == 200) {
                this.verifyState = true;
                this.verifyImg = data.data;
            } else {
                this.$message.error(data.message);
            }
        },
        // 场景配置
        initThree() {
            // 初始化渲染器配置
            this.initRenderer();
            // 初始化镜头配置
            this.initCamera();
            // 初始化粒子模型
            this.initObject();
            // 设置循环执行
            renderer.setAnimationLoop(this.render);
            // 监听屏幕尺寸变化自适应
            window.addEventListener("resize", () => {
                // 更新屏幕宽度
                w = window.innerWidth;
                // 更新屏幕高度
                h = window.innerHeight;
                // 设置渲染器渲染宽高
                renderer.setSize(w, h);
                // 设置镜头宽高比
                camera.aspect = w / h;
                // 更新相机视角矩阵
                camera.updateProjectionMatrix();

            });

        },
        // 镜头配置
        initCamera() {
            // 设置镜头坐标
            camera.position.set(0, 0, 24);
            // 设置镜头观察点为场景坐标原点
            camera.lookAt(scene.position);

        },
        // 渲染器配置
        initRenderer() {
            // 设置渲染分辨率
            renderer.setPixelRatio(window.devicePixelRatio);
            // 设置渲染场景宽高
            renderer.setSize(w, h);
            // 设置渲染背景色
            renderer.setClearColor(0x160016, 1);
            // 绑定渲染器
            this.$refs['earthContainer'].appendChild(renderer.domElement);

        },
        // 控制器配置
        initControls() {
            // controls.enableDamping = true;
            // controls.enablePan = false;
        },
        // 初始化粒子模型
        initObject() {
            // const geometry = new THREE.SphereGeometry(1, 64, 64);
            const count1 = 50000;
            const count2 = 100000;

            const geometry = new THREE.BufferGeometry();
            const positions = [];
            const sizes = [];
            const shifts = [];
            for (let i = 0; i < count1 + count2; i++) {
                let theta = Math.random() * Math.PI * 2;
                // let phi = Math.random() * Math.PI;
                let phi = Math.acos(Math.random() * 2 - 1);
                let angle = (Math.random() * 0.9 + 0.1) * Math.PI * 0.1;
                let strength = Math.random() * 0.9 + 0.1; // 0.1-1.0
                shifts.push(theta, phi, angle, strength);

                let size = Math.random() * 1.5 + 0.5; // 0.5-2.0
                sizes.push(size);

                if (i < count1) {
                    // 中心球体粒子
                    // let r = 10;
                    let r = Math.random() * 0.5 + 9.5;
                    // let x = r * Math.sin(phi) * Math.cos(theta);
                    // let y = r * Math.sin(phi) * Math.sin(theta);
                    // let z = r * Math.cos(phi);
                    let { x, y, z } = new THREE.Vector3()
                        .randomDirection()
                        .multiplyScalar(r);
                    positions.push(x, y, z);
                } else {
                    // 外围圆盘粒子
                    let r = 10;
                    let R = 40;
                    let rand = Math.pow(Math.random(), 1.5);
                    let radius = Math.sqrt(R * R * rand + (1 - rand) * r * r); // 通过 rand=0-1 数值去线性插值 R^2 和 r^2 大概是按圆圈面积采样粒子分布更均匀
                    let { x, y, z } = new THREE.Vector3().setFromCylindricalCoords(
                        radius, // 半径
                        Math.random() * 2 * Math.PI, // 角度
                        (Math.random() - 0.5) * 2 // 高度y -1-1
                    );
                    positions.push(x, y, z);
                }
            }

            geometry.setAttribute(
                "position",
                new THREE.Float32BufferAttribute(positions, 3)
            );
            geometry.setAttribute("aSize", new THREE.Float32BufferAttribute(sizes, 1));
            geometry.setAttribute("aShift", new THREE.Float32BufferAttribute(shifts, 4));

            const vertexShader = /* GLSL */ `
  attribute float aSize;
  attribute vec4 aShift;

  uniform float uTime;

  varying vec3 vColor;

  const float PI = 3.141592653589793238;

  void main() {
      // float d = abs(position.y) / 10.0;
      float d = length(abs(position) / vec3(40., 10., 40.));
      d = clamp(d, 0., 1.);
      
      // rgb(227, 155, 0)
      // rgb(100, 50, 255)
      vec3 color1 = vec3(234., 91., 25.);
      vec3 color2 = vec3(237., 145., 29.);
      vColor = mix(color1, color2, d) / 255.;

      vec3 transformed = position;
      float theta = mod(aShift.x + aShift.z * uTime, PI * 2.);
      float phi = mod(aShift.y + aShift.z * uTime, PI * 2.);
      transformed += vec3(sin(phi) * cos(theta), cos(phi), sin(phi) * sin(theta)) * aShift.w;
      
      vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
      gl_PointSize = aSize * 50.0 / -mvPosition.z;
      gl_Position = projectionMatrix * mvPosition;
  }
`;

            const fragmentShader = /* GLSL */ `
  varying vec3 vColor;

  void main() {
    float d = length(gl_PointCoord.xy - 0.5);
    if (d > 0.5) discard;
    // gl_FragColor = vec4(vColor, step(0.5, 1.0 - d));
    gl_FragColor = vec4(vColor, smoothstep(0.5, 0.1, d));
  }
`;
            //   vec3 color1 = vec3(28., 214., 108.);
            //   vec3 color2 = vec3(100., 50., 255.);

            this.materialObj = new THREE.ShaderMaterial({
                vertexShader,
                fragmentShader,
                uniforms: {
                    uTime: { value: 0 },
                },
                transparent: true,
                blending: THREE.AdditiveBlending,
                depthTest: false,
            });

            // const mesh = new THREE.Mesh(geometry, this.materialObj);
            this.meshObj = new THREE.Points(geometry, this.materialObj);
            this.meshObj.rotation.order = "ZYX";
            this.meshObj.rotation.z = 0.2;
            scene.add(this.meshObj);
        },
        // 渲染
        render() {

            let time = clock.getElapsedTime();

            this.meshObj.rotation.y = time * 0.01;

            this.materialObj.uniforms.uTime.value = time;
            // 渲染内容到场景中
            renderer.render(scene, camera);
            // 控制器更新
            controls.update();

        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>

<style>
.earth_container {
    perspective: 1000px;
}

.a {
    color: rgba(227, 155, 0, 1);
    background: rgba(100, 50, 255, 1);
}

/* 主页顶栏 */
.codics-huayuan-threejs-factory-home-top {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 40px;
    left: 0;
}

.codics-huayuan-threejs-factory-home-top-logo {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 5px;
    filter: drop-shadow(1040px 0px 0px #FFF);
    transform: translateX(-1000px);
}

.codics-huayuan-threejs-factory-home-bottom {
    height: 40px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    line-height: 40px;
    text-align: center;
    color: #FFF !important;
    font-size: 13px;
}

.codics-huayuan-threejs-factory-home-bottom a {
    color: rgba(255, 255, 255, .85) !important;
}

.ccodics-huayuan-threejs-factory-home-top-txt {
    font-size: 14px;
    height: 100%;
    width: auto;
    position: relative;
    float: right;
    line-height: 60px;
    text-align: center;
    /* color: #333; */
    color: #FFF;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    transition: .3s;
    /* text-decoration: none; */
}

/* 顶栏菜单栏 */
.codics-huayuan-threejs-factory-home-sub-bar {
    max-height: 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--backColor);
    transition: .3s;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 219.5px 0 219.5px;
    box-shadow: rgba(0, 0, 0, 0.07) 20px 20px 20px;
    cursor: auto;
    user-select: none;
}

/* 顶栏菜单栏标题 */
.codics-huayuan-threejs-factory-home-sub-bar-title {
    height: 70px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 70px;
    text-align: left;
    padding-top: 60px;
}

.ccodics-huayuan-threejs-factory-home-top-txt:hover .codics-huayuan-threejs-factory-home-sub-bar {
    max-height: 70vh;
}

.codics-huayuan-threejs-factory-home-sub-left-menu-box {
    height: 100%;
    width: 20%;
    position: relative;
    float: left;
    padding-bottom: 30px;
    box-sizing: border-box;
}

.codics-huayuan-threejs-factory-home-sub-left-menu-item {
    height: 60px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 60px;
    text-align: left;
    font-size: 14px;
    padding: 0 0 0 20px;
    border-right: 1px solid rgba(255, 255, 255, .4);
    cursor: pointer;
    transition: .4s;
    box-sizing: border-box;
}

.codics-huayuan-threejs-factory-home-sub-left-menu-item-enter {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: var(--fontCol);
}

.codics-huayuan-threejs-factory-home-sub-right-app-box {
    height: 100%;
    width: 80%;
    position: relative;
    float: left;
}

.codics-huayuan-threejs-factory-home-sub-right-app-item {
    height: 90px;
    width: 33.33333%;
    position: relative;
    float: left;
    cursor: pointer;
    transition: .4s;
}

.codics-huayuan-threejs-factory-home-sub-right-app-item:hover {
    background: var(--shadeColor);
}

.codics-huayuan-threejs-factory-home-sub-right-app-item-img {
    height: 40px;
    width: 18%;
    position: relative;
    float: left;
    padding: 12px;
    box-sizing: border-box;
}

.codics-huayuan-threejs-factory-home-sub-right-app-item-name {
    height: 35px;
    width: 82%;
    font-weight: normal;
    color: #303030;
    font-size: 14px;
    line-height: 40px;
    color: var(--fontCol);
    text-align: left;
    position: relative;
    float: right;
}

.codics-huayuan-threejs-factory-home-sub-right-app-item-desc {
    height: 50%;
    width: 82%;
    font-weight: normal;
    color: #303030;
    font-size: 12px;
    line-height: 20px;
    color: var(--hintColor);
    text-align: left;
    position: relative;
    float: right;
    padding-right: 20px;
    box-sizing: border-box;
    transition: .4s;
}




/* 登录输入框容器 */
.codics-huayuan-threejs-factory-home-login-login-box {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    pointer-events: all !important;
}

/* 轮播盒子 */
.codics-huayuan-threejs-factory-home-login-carousel-box {
    height: 362px;
    width: 650px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0px 0;
}

.codics-huayuan-threejs-factory-home-login-carousel {
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
}

.codics-huayuan-threejs-factory-home-login-carousel-item {
    height: 100%;
    width: 100%;
    background: #333;
}

.codics-huayuan-threejs-factory-home-login-view {
    height: 362px;
    width: 380px;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    padding: 25px;
}

.codics-huayuan-threejs-factory-home-login-title-box {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
}

.codics-huayuan-threejs-factory-home-login-title {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
    color: var(--fontCol);
    font-size: 14px;
    font-weight: 500;
    border-bottom: var(--idxState);
    cursor: pointer;
    user-select: none;
}

/* 验证码图片 */
.codics-huayuan-threejs-factory-home-login-input-card-code {
    height: 25px;
    width: 62.5px;
    top: 2.5px;
    box-sizing: border-box;
    cursor: pointer;
    color: #333;
    font-size: 11px;
}

.codics-huayuan-threejs-factory-home-login-verify-code .el-input-group__append {
    padding: 0 10px;
}

.codics-huayuan-threejs-factory-home-login-input-card-login {
    width: 100%;
}

input:-webkit-autofill {
    transition: background-color 10000s ease-in-out 0s !important;
}

.codics-huayuan-threejs-factory-home-login-line {
    height: 55px;
    width: 100%;
    position: relative;
    float: left;
    /* margin-bottom: 10px; */
    line-height: 55px;
}

.codics-huayuan-threejs-factory-home-login-line-pass {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    line-height: 55px;
    font-size: 13px;
    color: #333;
    text-decoration: none;
}

@media (max-width:1030px) {
    .codics-huayuan-threejs-factory-home-login-carousel-box {
        display: none;
    }
}

.codics-huayuan-threejs-factory-home-login-qrcode {
    height: 120px;
    width: 120px;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* 取消[type='number']的input的上下箭头 */
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.codics-huayuan-threejs-factory-home-login-qrcode-tip {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 65%;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #333;
}
</style>