<template>
    <div class="codics-factory-admin-user-body">
        <div class="codics-factory-admin-user-control">
            <el-button class="codics-factory-admin-user-control-search" type="success" icon="el-icon-plus"
                @click="jumpToPage" plain>添加模型</el-button>
            <!-- 模型信息修改 -->
            <el-dialog title="模型信息修改" :visible.sync="updateModelVisible" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="模型标题" :label-width="formLabelWidth">
                        <el-input v-model="addForm.title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="模型密码" :label-width="formLabelWidth">
                        <el-input v-model="addForm.pass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="页面水印" :label-width="formLabelWidth">
                        <el-input v-model="addForm.wmark" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="加载Logo" :label-width="formLabelWidth">
                        <el-upload action="https://cdn.bosscaigou.com/" list-type="picture-card"
                            :on-preview="handlePictureCardPreview" class="codics-admin-model-logo-upload"
                            :http-request="uploadFile2" :on-remove="handleRemove2"
                            :style="{ '--himgSize': addForm.logo == '' ? 'inline-block' : 'none' }" ref="fmUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="背景音乐" :label-width="formLabelWidth">
                        <el-button type="primary">点击上传</el-button>
                    </el-form-item>
                    <el-form-item label="模型状态" :label-width="formLabelWidth">
                        <el-select v-model="addForm.state" placeholder="请选择模型状态">
                            <el-option label="有预览可申请" value=0></el-option>
                            <el-option label="无预览可申请" value=1></el-option>
                            <el-option label="无预览无申请" value=2></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分享标题" :label-width="formLabelWidth">
                        <el-input v-model="addForm.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="分享介绍" :label-width="formLabelWidth">
                        <el-input v-model="addForm.detail" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="分享LOGO" :label-width="formLabelWidth">
                        <el-upload action="https://cdn.bosscaigou.com/" list-type="picture-card"
                            :on-preview="handlePictureCardPreview" class="codics-admin-model-icon-upload"
                            :http-request="uploadFile3" :on-remove="handleRemove3"
                            :style="{ '--himgSize': addForm.icon == '' ? 'inline-block' : 'none' }" ref="fmUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="updateModelVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addModelFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-factory-admin-user-control-input" style="width: 300px !important;"
                placeholder="请输入模型名称/模型介绍..." v-model="searchTxt" clearable @input="searchChange">
                <el-button style="padding: 0 20px 0 20px;" slot="append" icon="el-icon-search"
                    @click="searchPage = 1; searchMax = 0; searchData = []; listModelsFuc();"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteModelsFuc">
                <el-button class="codics-factory-admin-user-control-search" slot="reference" type="danger"
                    icon="el-icon-delete" plain>批量删除</el-button>
            </el-popconfirm>
            <el-button class="codics-factory-admin-user-control-search"
                style="float: right !important;margin-right: 0 !important;" icon="el-icon-download"
                @click="tableToExcel" plain>导出数据</el-button>
        </div>
        <el-table stripe class="codics-factory-admin-user-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="120">
            </el-table-column>
            <el-table-column prop="url" label="链接" width="180">
                <template slot-scope="scope">
                    {{ scope.row.url }}
                    <el-upload action="cdn.bosscaigou.com" accept=".glb,.gltf"
                        :http-request="(params) => uploadFile(params, scope.row.id)" :limit="1" :show-file-list="false">
                        <el-button type="warning" style="margin-left: 10px;" size="mini">点击上传</el-button>
                    </el-upload>
                </template>
            </el-table-column>
            <el-table-column prop="pass" label="密码" width="80">
            </el-table-column>
            <el-table-column prop="wmark" label="水印" width="100">
            </el-table-column>
            <el-table-column prop="logo" label="图标" width="50">
                <template slot-scope="scope">
                    <el-image style="top:3.5px"
                        :src="scope.row.logo == null ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : ('https://cdn.bosscaigou.com/' + scope.row.logo)"
                        fit="fill">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="bgm" label="背景音乐" width="80">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }]" :filter-method="memberFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? '' : 'danger'" disable-transitions size="mini">{{
                    scope.row.state == 0 ?
                        '上架' : '下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="分享标题" width="100">
            </el-table-column>
            <el-table-column prop="detail" label="分享介绍" width="100">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" slot="reference" style="" @click="jumpToShow(scope);">跳转</el-button>
                    <el-button size="mini" slot="reference" style="margin-right: 10px;"
                        @click="handleEdit(scope)">编辑</el-button>
                    <!-- <el-button size=" mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteModelsFuc([scope.row.id])">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-user-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { listModel, getQiNiuToken, deleteModels, updateModel, updateModel2 } from '@/api';
import { jumpTo, exportExcel } from '@/utils/myutils';
import * as qiniu from 'qiniu-js';
import { hex_md5 } from '@/utils/md5';
export default {
    data() {
        return {
            updateModelVisible: false,
            formLabelWidth: '110px',
            currentPage: 1,
            searchPage: 1,
            searchTxt: '',
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            selectData: [],
            nowSearchTxt: '',
            qiNiuInfo: {
                token: null
            },
            addForm: {
                id: 0,
                title: '',
                pass: '',
                wmark: '',
                logo: '',
                bgm: '',
                state: 0,
                name: "",
                detail: '',
                icon: ''
            }
        }
    },
    mounted() {
        this.listModelsFuc();
        this.getTokenFuc();
    },
    methods: {
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '状态', '快递', '快递单号', "商品", "商品介绍", '现价', '原价', '姓名', '手机号', '省份', '城市', '区域', '具体地址', '卡券号', '卡券密码'];
            let filter = ['cid', 'sid', 'pid', 'aid', 'count', 'product.id', 'product.img', 'product.itimg', 'product.type', 'product.sid', 'product.state', 'product.createby', 'product.createtime', 'product.updateby', 'product.updatetime', 'product.brand', 'product.inventory', 'product.like', 'product.view', 'product.sell', 'product.follow', 'area.id', 'area.uid', 'productCdk.id', 'productCdk.state', 'productCdk.expires', 'productCdk.count', 'productCdk.sid', 'productCdk.createby', 'productCdk.createtime', 'productCdk.updateby', 'productCdk.updatetime', 'productCdk.product', 'createby', 'createtime', 'updateby', 'updatetime'];  //不导出的字段
            exportExcel(this.selectData, '用户订单数据_' + year + month + day, title, filter);
        },
        async addModelFuc() {

            if (this.addForm.title == '') {

                this.$message.error('模型标题不能为空！');

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.addForm.pass = this.addForm.pass == '' ? '' : hex_md5(this.addForm.pass);

            let data = await updateModel(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '更新信息成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }
        },
        // 上传方法
        uploadFile(uploadInfo, id) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.addForm.url = 'huayuan/model/' + uploadInfo.file.name.replace(',', '');

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    _that.updateModelFuc2(id, '/' + res.key);
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/model/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        async updateModelFuc2(id, url) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await updateModel2({ id: id, url: url });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '更新模型成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        // 上传方法
        uploadFile2(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.logo = '/' + res.key;
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 上传方法
        uploadFile3(uploadInfo) {

            const loading = this.$loading({
                lock: true,
                text: '上传中(0%)...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    loading.text = '上传中(' + res.total.percent.toFixed(2) + "%)...";
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.icon = '/' + res.key;
                    loading.close();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        jumpToPage() {

            jumpTo(this, "模型上传", {}, {});

        },
        jumpToShow(scope) {
            window.open(window.location.origin + "/show/" + scope.row.id, '_blank');
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async rePassFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await rePass({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据重置成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async deleteModelsFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteModels({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async searchUsersFuc() {

            if (this.searchTxt == '') {

                this.$message.error('请先输入要搜索的内容！');

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await searchUsers2({ key: this.searchTxt, pge: this.searchPage, psz: this.searchSize });
            loading.close();
            if (data.code == 200) {
                this.searchData = data.data;
                this.searchMax = data.count;
            } else {
                this.$message.error(data.message);
            }

        },
        async listModelsFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listModel({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.searchTxt == '') {

                    this.tableData = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        async getUserListData(pge, psz) {

            let data = await getUserList({ 'pge': pge, 'psz': psz });

            if (data.code == 200) {

                this.tableData = data.data;

                this.maxSize = data.count;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
                this.getUserListData(this.currentPage, val);
            } else {
                this.searchSize = val;
                this.searchUsersFuc();
            }
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
                this.getUserListData(val, this.dataSize);
            } else {
                this.searchPage = val;
                this.searchUsersFuc();
            }
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        memberFilter(v, r, c) {

            return r.member === v;

        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        wxFilter(v, r, c) {

            let t = r.wxkey === null || r.wxkey === '';

            return v == 0 ? !t : t;

        },
        qqFilter(v, r, c) {

            let t = r.qkey === null || r.qkey === '';

            return v == 0 ? !t : t;

        },
        async resetPass(idx, row) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await rePass({ id: row.id });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '账号密码重置成功！',
                    type: 'success'
                });
            } else {
                this.$message.error(data.message);
            }

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        handleEdit(scope) {
            this.addForm.id = scope.row.id;
            this.addForm = { ...scope.row };
            this.updateModelVisible = true;
        },
        // 图片点击预览
        handlePictureCardPreview(file) {

        },
        handleRemove() {
            this.addForm.url = '';
            this.updateForm.url = '';
        },
        handleRemove2() {
            this.addForm.logo = '';
            this.updateForm.logo = '';
        },
        handleRemove3() {
            this.addForm.icon = '';
            this.updateForm.icon = '';
        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-user-body {
    height: 100%;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
    overflow-y: scroll;
}

/* 后台管理页面主页 */
.codics-factory-admin-user-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-user-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-user-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-user-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-user-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-factory-admin-user-table .el-table__cell {
    padding: 8px 0 !important;
}
</style>