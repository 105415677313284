<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { delCookie, getCookie, setCookie } from '@/utils/mycookie';
import { randomString, jumpTo } from '@/utils/myutils';
import { hex_md5 } from '@/utils/md5';
// 初始化设备唯一识别码
if (getCookie('x-myweb-key') == null) {
  // 测试性能：开始计时
  // console.time('codeExecution');
  // console.log(hex_md5(new Date().valueOf().toString() + randomString(20)));
  // 测试性能：结束计时
  // console.timeEnd('codeExecution');
  //设置设备唯一识别码
  setCookie('x-myweb-key', hex_md5(new Date().valueOf().toString() + randomString(50)));

}
export default {
  name: 'App',
  components: {
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {
    return {
      title: this.$route.meta.metaInfo.title,
      meta: [
        { name: "keywords", content: this.$route.meta.metaInfo.keywords },
        { name: "description", content: this.$route.meta.metaInfo.description },
      ]
    }
  }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  background: #f8f8f8;
}
</style>
