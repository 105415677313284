<template>
    <div class="codics-factory-admin-apply-body">
        <div class="codics-factory-admin-apply-control" style="margin-bottom: 15px;">
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteApplysFuc">
                <el-button class="codics-factory-admin-apply-control-search" slot="reference" type="danger"
                    icon="el-icon-delete" plain>删除</el-button>
            </el-popconfirm>
            <el-dropdown split-button type="primary" @click="applyMemberFuc(null, 1);"
                @command="applyMemberFuc(null, 2)">
                批量审核
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>批量拒绝</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-table stripe class="codics-factory-admin-apply-table" :data="applyList" border style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="model.title" label="模型名称" width="180">
            </el-table-column>
            <el-table-column prop="model.url" label="模型照片" width="80">
                <template slot-scope="scope">
                    <el-image style="top:3.5px"
                        :src="scope.row.model.icon == '' ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : ('https://cdn.bosscaigou.com/' + scope.row.model.icon)"
                        fit="fill">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="user.account" label="申请账号" width="110">
            </el-table-column>
            <el-table-column prop="user.nickname" label="申请人" width="110">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="80"
                :filters="[{ text: '待审核', value: 0 }, { text: '已通过', value: 1 }, { text: '已拒绝', value: 2 }, { text: '已过期', value: 3 }]"
                :filter-method="stateFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'info' : (scope.row.state == 1 ? 'success' : 'danger')"
                        disable-transitions>{{ scope.row.state == 0 ? '待审核' :
                (scope.row.state == 1 ? '已通过' : (scope.row.state == 3 ? '已过期' : '已拒绝')) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="ctime" label="到期时间" width="250">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-dropdown v-show="scope.row.state == 0 || !jsTime(scope.row)" split-button type="primary"
                        size="mini" @click="applyMemberFuc([scope.row.id], 1);"
                        @command="applyMemberFuc([scope.row.id], 2)" style="margin-right: 10px;">
                        通过申请
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click="examineApplyFuc([scope.row.id], 2);">拒绝申请</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteApplysFuc([scope.row.id]);">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-apply-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="dataSize" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
        <el-dialog title="通过申请" :visible.sync="applyVisible">
            <el-form :model="addForm">
                <el-form-item label="活动名称" :label-width="formLabelWidth">
                    <el-date-picker v-model="applyTime" @change="changeTime" value-format="timestamp" type="datetime"
                        :picker-options="pickerOptions" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="applyVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmApply(1)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addProductType, listApplys, examineApply, deleteApplys, listMember, applyMember,deleteMembers } from '@/api';
export default {
    data() {
        return {
            pickerOptions: { // 限制收货时间不让选择今天以前的
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                },
                shortcuts: [{
                    text: '一天',
                    onClick(picker) {
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24);
                        picker.$emit('pick', end);
                    }
                }, {
                    text: '一周',
                    onClick(picker) {
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', end);
                    }
                }, {
                    text: '一个月',
                    onClick(picker) {
                        const end = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 31);
                        picker.$emit('pick', end);
                    }
                }]
            },
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            applyVisible: false,
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            applyList: [],
            shshVisible: [],
            selectData: [],
            searchTxt: '',
            applyTime: new Date().getTime(),
            nowIds: []
        }
    },
    mounted() {

        this.listMembersData();

    },
    methods: {
        changeTime(a) {
            console.log(12312312313, a)
        },
        jsTime(item) {
            return new Date(item.ctime) > new Date();
        },
        initMem(list) {
            list.forEach(item => {
                if (item.state == 1 && !this.jsTime(item)) {
                    item.state = 3;
                }
            });
        },
        async listMembersData(key) {

            let data = await listMember({ pge: this.currentPage, psz: this.dataSize, mid: 0, uid: 0 });

            if (data.code == 200) {

                this.initMem(data.data);

                this.applyList = data.data;

                this.maxSize = data.data1;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.listMembersData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.listMembersData();
        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductTypeFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '分类名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductType(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品分类成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async applyMemberFuc(ids, state) {

            if (ids == null) {

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            if (ids.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要审核的数据！',
                    type: 'warning'
                });

                return true;

            }

            this.nowIds = ids;

            this.applyVisible = true;

        },
        async confirmApply(state) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            console.log(state)
            let data = await applyMember({ 'ids': this.nowIds, state: state, time: this.applyTime });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '数据审核成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async deleteApplysFuc(t) {
            if (t == null) {
                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }
                t = [];
                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteMembers({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }

        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-apply-body {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-factory-admin-apply-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-apply-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-apply-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-apply-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-apply-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>