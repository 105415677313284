<template>
    <div class="codics-threejs-show-body" ref="threeShow">
        <div class="codics-threejs-show-desc"
            :style="{ 'visibility': sysMode == 0 ? 'visible' : 'hidden', 'opacity': sysMode == 0 ? '1' : '0' }">
            <span>W / ↑ 向前移动</span>
            <span>S / ↓ 向后移动</span>
            <span>A / ← 向左移动</span>
            <span>D / → 向右移动</span>
            <span>shift 加速移动</span>
            <span>space 跳跃</span>
            <span>碰撞到物体不可继续移动</span>
        </div>
        <div class="codics-threejs-show-desc"
            :style="{ 'visibility': sysMode == 1 ? 'visible' : 'hidden', 'opacity': sysMode == 1 ? '1' : '0' }">
            <span>ctrl 拖动切换中心点</span>
            <span>鼠标拖拽切换镜头方向</span>
            <span>鼠标滚轮放大缩小镜头距离</span>
        </div>
        <div class="codics-threejs-show-frame"
            :style="{ 'visibility': (btnShow && sysMode == 0) ? 'visible' : 'hidden', 'opacity': (btnShow && sysMode == 0) ? '1' : '0' }">
            <el-button v-show="loadProgress == 100" class="codics-threejs-show-frame-btn" ref="frameBtn"
                @click="controlsLock()">点我进入</el-button>
            <el-progress class="codics-threejs-show-frame-progress" v-show="loadProgress < 100" type="circle"
                :percentage="loadProgress"></el-progress>
        </div>
        <el-image class="codics-threejs-show-top-img" v-show="false" :style="{ 'top': sysMode == 0 ? '-100px' : '5px' }"
            src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_0507e9e0eda5c2e912544dcd8f44df7.webp"
            fit="fill"></el-image>
        <div class="codics-threejs-show-top-title" v-show="false" :style="{ 'top': sysMode == 0 ? '-100px' : '5px' }">
            工厂可视化智慧数字大屏</div>
        <el-image class="codics-threejs-show-side-img" v-show="false"
            :style="{ 'transform': 'scaleX(.65)', 'transform-origin': '0 center', 'left': sysMode == 0 ? '-100px' : '0' }"
            src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%B7%A6%E8%BE%B9%E6%A1%86.webp" fit="fill"></el-image>
        <el-image class="codics-threejs-show-side-img" v-show="false"
            src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%B7%A6%E8%BE%B9%E6%A1%86.webp"
            :style="{ 'left': 'unset !important', 'right': sysMode == 0 ? '-100px' : '0', 'transform-origin': '60% center', 'transform': 'scaleX(.65) rotateY(180deg)' }"
            fit="fill"></el-image>
        <el-image class="codics-threejs-show-content-img" v-show="false"
            :style="{ 'visibility': sysMode == 1 ? 'visible' : 'hidden', 'opacity': sysMode == 1 ? '1' : '0' }"
            src="https://cdn.bosscaigou.com/threejs/imgs/tuan%20%E6%8B%B7%E8%B4%9D23.png" fit="fill"></el-image>
        <el-image class="codics-threejs-show-content-img" v-show="false"
            src="https://cdn.bosscaigou.com/threejs/imgs/tuan%20%E6%8B%B7%E8%B4%9D23.png"
            :style="{ 'left': ' unset !important', 'right': '50px', 'transform': 'rotate(180deg)', 'visibility': sysMode == 1 ? 'visible' : 'hidden', 'opacity': sysMode == 1 ? '1' : '0' }"
            fit="fill"></el-image>
        <el-image class="codics-threejs-show-bottom-img" v-show="false"
            src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%8F%B3.webp"
            style="transform: rotateY(180deg) translateX(68%);" fit="fill"></el-image>
        <el-image class="codics-threejs-show-bottom-img" v-show="false"
            src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E5%8F%B3.webp" style="transform: translateX(68%);"
            fit="fill"></el-image>
        <div class="codics-threejs-show-bottom-btn" data-content="" style="left: -68%;" @click="reset">
            <div class="codics-threejs-show-bottom-btn-txt">初始化系统</div>
        </div>
        <!-- <div v-show="false" class="codics-threejs-show-bottom-btn" data-content=""
            :style="{ 'left': '-54%', 'filter': rotateState ? 'drop-shadow(0 0 3px rgba(255,255,255,.5))' : 'unset' }"
            @click="rotateClickFuc">
            <div class="codics-threejs-show-bottom-btn-txt">{{ rotateState ? '关闭' : '旋转' }}</div>
        </div> -->
        <div class="codics-threejs-show-bottom-btn" data-content="" :style="{ 'left': '54%' }" @click="changeMode(0);">
            <div class="codics-threejs-show-bottom-btn-txt">第一人称浏览模式</div>
        </div>
        <div class="codics-threejs-show-bottom-btn" data-content="" :style="{ 'left': '68%' }" @click="changeMode(1);">
            <div class="codics-threejs-show-bottom-btn-txt">全局浏览模式</div>
        </div>
        <!-- <div class="codics-threejs-show-bottom-btn codics-threejs-show-bottom-mode-btn" data-content=""
            style="left: 54%;overflow: visible !important;z-index: 11 !important;">
            <div class="codics-threejs-show-bottom-btn-txt">{{ ['第一', '轨道', '相机'][sysMode] }}</div>
            <div class="codics-threejs-show-bottom-mode-list">
                <div class="codics-threejs-show-bottom-mode-item" v-for="(item, index) in ['第一', '轨道', '相机']"
                    @click="sysMode = index; changeMode(index);" :key="index">
                    {{ item }}</div>
            </div>
        </div> -->
        <!-- <div class="codics-threejs-show-bottom-btn" data-content="" v-show="false"
            :style="{ 'left': '40%', 'filter': rotateState ? 'drop-shadow(0 0 3px rgba(255,255,255,.5))' : 'unset' }"
            @click="rotateClickFuc">
            <div class="codics-threejs-show-bottom-btn-txt">{{ rotateState ? '关闭' : '透视' }}</div>
        </div> -->
        <div class="codics-threejs-show-bottom-btn iconfont" data-content="" style="left: -54%;" @click="mp3Play">
            <div class="iconfont codics-threejs-show-bottom-btn-txt"
                v-html="audioState ? '&#xe825; 暂停' : '&#xe826; 播放'">
            </div>
        </div>
        <!-- <div class="codics-threejs-show-bottom-btn" data-content="" @click="tagState = !tagState;"
            :style="{ 'left': '-40%', 'filter': tagState ? 'drop-shadow(0 0 3px rgba(255,255,255,.5))' : 'unset' }">
            <div class="codics-threejs-show-bottom-btn-txt">{{ tagState ? '关闭' : '开启' }}</div>
        </div> -->
        <!-- <div v-show="false" class="codics-threejs-show-bottom-btn" data-content="" style="left: 54%;"
            @click="changeLayer">
            <div class="codics-threejs-show-bottom-btn-txt">切换</div>
        </div> -->
        <!-- <div class="codics-threejs-show-bottom-btn" data-content="" @click="changeCamera" v-show="false"
            :style="{ 'left': '68%', 'filter': visitState ? 'drop-shadow(0 0 3px rgba(255,255,255,.5))' : 'unset' }">
            <div class="codics-threejs-show-bottom-btn-txt">{{ !visitState ? '游览' : '关闭' }}</div>
        </div> -->
        <div class="codics-threejs-show-css-tag" v-for="(item, index) in tagInfo" :key="index" :id="'cssTag' + index"
            :style="{ '--bckColor': item.color }" v-show="tagState && sysMode == 1">
            <div class="codics-threejs-show-css-tag-btn" v-show="tagState">
                <div class="codics-threejs-show-css-view" style="opacity: 0;">
                    <div class="codics-threejs-show-css-tag-top">{{ item.name }}</div>
                    <div class="codics-threejs-show-css-tag-content">{{ item.desc }}</div>
                    <div class="codics-threejs-show-css-tag-triangle"></div>
                </div>
            </div>
        </div>
        <div class="codics-threejs-show-load-view" :data-content="loadProgress"
            :style="{ 'pointer-events': loadProgress >= 99 ? 'none' : 'all', 'visible': loadProgress >= 99 ? 'hidden' : 'visible', 'opacity': loadProgress >= 99 ? '0' : '1' }">
            <!-- https://cdn.bosscaigou.com/threejs/imgs/%E4%B8%AD%E5%BF%831.png -->
            <!-- https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E4%B8%AD%E5%BF%831.webp -->
            <el-image v-show="!applyError"
                class="codics-threejs-show-load-img codics-threejs-show-load-img-one spin-element"
                src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_111.webp" fit="cover"></el-image>
            <!-- https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E4%B8%AD%E5%BF%832.webp -->
            <el-image v-show="!applyError"
                class="codics-threejs-show-load-img codics-threejs-show-load-img-two spin-element2"
                src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_222.webp" fit="cover"></el-image>
            <!-- https://cdn.bosscaigou.com/threejs/imgs/yasuo_%E4%B8%AD%E5%BF%833.webp -->
            <el-image v-show="!applyError"
                class="codics-threejs-show-load-img codics-threejs-show-load-img-three spin-element3"
                src="https://cdn.bosscaigou.com/threejs/imgs/yasuo_333.webp" fit="cover"></el-image>
            <el-image v-show="!applyError" class="codics-threejs-show-load-img codics-threejs-show-load-img-four"
                src="https://cdn.bosscaigou.com/huayuan/img/icon.svg" fit="fill"></el-image>
        </div>
        <!-- <div class="swiper-container"
            :style="{ 'visibility': swiperState ? 'visible' : 'hidden', 'opacity': swiperState ? '1' : '0' }">
            <div class="swiper-wrapper">
                <div v-for="item in swiperList" :key="item.id" class="swiper-slide">
                    <div class="swiper-slide-card" :style="{ 'background-image': `url(${item.imgUrl})` }"></div>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div> -->
        <!-- <div class="swiper-container-switch" :data-content="swiperState ? '收起列表' : '展开列表'" @click="swiperFuc();">
            <img v-show="false" src="https://cdn.bosscaigou.com/threejs/imgs/PicsArt_10-31-05.29.03.png">
        </div> -->
        <el-image v-show="false" class="swiper-container-switch-arrow swiper-container-switch" @click="swiperFuc();"
            :style="{ 'visibility': !swiperState ? 'visible' : 'hidden', 'opacity': !swiperState ? '1' : '0' }"
            src="https://cdn.bosscaigou.com/threejs/imgs/arrow%20%282%29.svg" fit="fill"></el-image>
        <div class="codics-threejs-show-water-mark"></div>
        <el-dialog title="输入密码" :visible.sync="passDialog" :before-close="goTo" :close-on-click-modal="false">
            <el-form :model="form">
                <el-form-item label="模型密码" :label-width="formLabelWidth">
                    <el-input v-model="passStr" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="checkPass">确 定</el-button>
            </div>
        </el-dialog>
        <div v-show="applyError" class="codics-threejs-show-body-apply-error">
            <el-image
                src="https://cdn.bosscaigou.com/PDF%E8%BD%AC%E7%94%BB%E5%86%8C/img/%E6%9A%82%E6%97%A0%E6%9D%83%E9%99%90.svg"
                fit="fill" class="codics-threejs-show-body-apply-error-img"></el-image>
            <div class="codics-threejs-show-body-apply-error-txt">
                您还没有权限访问当前模型，请先添加客服微信“13155553831”，向客服说明您的浏览需求，并点击下面“去申请访问权限”按钮完成当前模型的申请，申请通过后，即可浏览当前模型！</div>
            <el-button type="warning" class="codics-threejs-show-body-apply-error-btn"
                @click="goToList">去申请访问权限</el-button>
        </div>
        <div class="iconfont codics-threejs-show-body-back" @click="goToList">&#xe7eb; 返回列表</div>
    </div>
</template>
<style>
.codics-threejs-show-body-back{
    height: 50px;
    width: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    line-height: 50px;
    text-align: center;
    user-select: none !important;
    -webkit-user-drag: none !important;
    color: #333;
    z-index: 10000;
    padding: 0 25px;
    cursor: pointer;
}
.codics-threejs-show-water-mark {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    pointer-events: none;
    /* background: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='70%25' font-size='14' fill-opacity='0.3' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 100 100)'%3E奥纳尔内部机密文件，请勿外传%3C/text%3E%3C/svg%3E") repeat; */
}

.codics-threejs-show-css-label-show {
    visibility: hidden !important;
    opacity: 0 !important;
}

.codics-threejs-show-css-label-hidden {
    visibility: visible !important;
    opacity: 1 !important;
}

.codics-threejs-show-css-tag-btn {
    height: 10px;
    width: 10px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    background: var(--bckColor);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.codics-threejs-show-css-tag-triangle {
    height: 0;
    width: 0;
    position: absolute;
    margin: auto;
    left: 10px;
    right: 0;
    bottom: -19px;
    border: 10px solid var(--bckColor);
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
}

.codics-threejs-show-css-tag-btn:hover .codics-threejs-show-css-view {
    opacity: 1 !important;
    visibility: visible !important;
}

.codics-threejs-show-css-tag-btn::before,
.codics-threejs-show-css-tag-btn::after {
    content: '';
    height: 100px;
    width: 100px;
    position: absolute;
    left: -47.5px;
    top: -47.5px;
    border: 2px solid var(--bckColor);
    background: var(--bckColor);
    opacity: .8;
    border-radius: 50%;
    opacity: 0;
    z-index: 1;
    pointer-events: none !important;
}

.codics-threejs-show-css-tag-btn::before {
    background: var(--bckColor) !important;
    -webkit-animation: homeTipOne 0.9s ease-out;
    -moz-animation: homeTipOne 0.9s ease-out;
    animation: homeTipOne 0.9s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    box-shadow: 1px 1px 30px var(--bckColor);
    z-index: 0;
}

.codics-threejs-show-css-tag-btn::after {
    background: var(--bckColor) !important;
    opacity: .6;
    -webkit-animation: homeTipTwo 0.9s ease-out;
    -moz-animation: homeTipTwo 0.9s ease-out;
    animation: homeTipTwo 0.9s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    box-shadow: 1px 1px 30px var(--bckColor);
}

@keyframes homeTipOne {
    0% {
        -moz-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -moz-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes homeTipTwo {
    0% {
        -o-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -o-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0;
    }
}


.codics-threejs-show-load-img-four {
    height: 75px !important;
    width: 155px !important;
    left: 90px !important;
    top: 5px !important;
}

.codics-threejs-show-bottom-btn-txt {
    height: 25px;
    width: 100px;
    position: relative;
    float: left;
    line-height: 25px;
    text-align: center;
    font-size: 11px;
    color: #FFF;
    background: #0DBC79;
    line-height: 25px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, .1);
    user-select: none !important;
    -webkit-user-drag: none !important;
}

.codics-threejs-show-bottom-mode-btn:hover .codics-threejs-show-bottom-mode-list {
    opacity: 1;
    visibility: visible;
}

.codics-threejs-show-bottom-mode-list {
    height: fit-content;
    width: 100%;
    position: absolute;
    margin: auto;
    top: -115px;
    left: 0;
    right: 0;
    /* background: rgba(255, 255, 255, .25); */
    background: #0DBC79;
    border: .3px solid rgba(255, 255, 255, .32);
    /* backdrop-filter: blur(5px); */
    border-radius: 3px;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 112;
}

.codics-threejs-show-bottom-mode-item {
    height: 35px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 35px;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    color: #FFF;
    transition: .3s;
}

.codics-threejs-show-bottom-mode-item:hover {
    background: rgba(255, 255, 255, .4);
}

.codics-threejs-show-intro-box {
    height: 100%;
    width: 300px;
    position: absolute;
    margin: auto;
    left: 50px;
    box-sizing: border-box;
    padding: 50px 0;
}

.codics-threejs-show-intro {
    height: 500px;
    width: 100%;
    position: relative;
    float: left;
    background: rgba(255, 255, 255, .3);
    backdrop-filter: blur(5px);
}

.swiper-container-switch {
    height: 45px;
    width: 220px;
    position: absolute !important;
    margin: auto;
    bottom: 20px;
    left: 0;
    right: 0;
    overflow: hidden !important;
    box-sizing: border-box;
    z-index: 1;
    cursor: pointer !important;
    pointer-events: all !important;
    object-fit: fill;
}

.swiper-container-switch::after {
    content: attr(data-content);
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    /* top: 23px !important; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #FFF;
    font-size: 12px;
    user-select: none;
    text-align: center;
    pointer-events: none !important;
    line-height: 40px;
}

.swiper-container-switch {
    height: 40px !important;
    width: 100px !important;
    background: #0DBC79;
    color: #FFF;
    border-radius: 3px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, .1);
}

.swiper-container-switch img {
    height: 100%;
    width: 100%;
    -webkit-user-drag: none !important;
    user-select: none !important;
}

.swiper-container-switch-arrow {
    height: 30px !important;
    width: 150px !important;
    bottom: 60px !important;
    transition: .3s;
}

.codics-threejs-show-css-tag {
    height: 0 !important;
    width: 0 !important;
    /* position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    box-sizing: border-box;
    pointer-events: none !important;
    display: inline-block; */
    background: rgba(255, 255, 255, .2);
    backdrop-filter: blur(4px);
    box-sizing: border-box;
}

.codics-threejs-show-css-view {
    height: fit-content;
    width: 200px;
    position: absolute;
    margin: auto;
    transform: translateX(-50%);
    bottom: 24px;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}

.codics-threejs-show-css-tag-top {
    height: 28px;
    width: 100%;
    position: relative;
    float: left;
    border-left: 1px solid var(--bckColor);
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 28px;
    font-size: 12px;
    color: #333;
}

.codics-threejs-show-css-tag-content {
    height: fit-content;
    min-height: 30px;
    width: 100%;
    position: relative;
    float: left;
    background: linear-gradient(to right, rgba(255, 255, 255, .05), rgba(0, 0, 0, .3));
    padding: 3px 5px;
    line-height: 20px;
    font-size: 12px;
    color: #333;
    box-sizing: border-box;
}

.codics-threejs-show-css-tag-content::before {
    content: '';
    height: 4px;
    width: 4px;
    position: absolute;
    margin: auto;
    left: 0;
    bottom: -2px;
    border-radius: 2px;
    background: var(--bckColor);
    z-index: 1;
}

.codics-threejs-show-css-tag-content::after {
    content: '';
    height: 50px;
    width: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    bottom: -50px;
    border-top: 1px solid var(--bckColor);
    /* border-right: 1px solid rgba(255, 0, 83, 1); */
}

.codics-threejs-show-load-view {
    height: 100%;
    width: 100%;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .3);
    backdrop-filter: blur(5px);
    z-index: 111;
    transition: .5s;
}

.codics-threejs-show-load-view img {
    -webkit-user-drag: none !important;
    user-select: none !important;
}

.codics-threejs-show-load-view::after {
    content: attr(data-content);
    height: fit-content;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 350px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 28px;
    text-align: center;
    color: #43A4FE;
    color: #EA5C1A !important;
    /* font-size: 50px !important; */
    user-select: none;
}

.codics-threejs-show-load-img {
    height: 100px;
    width: 100px;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-threejs-show-load-img-two {
    height: 150px !important;
    width: 150px !important;
    transform-origin: 52.7% 52.7%;
    top: -9px !important;
    left: -9px !important;
}

.codics-threejs-show-load-img-three {
    height: 200px !important;
    width: 200px !important;
    top: -3px !important;
    left: -3px !important;
    transform-origin: 50.7% 50.7%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spinf {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

.spin-element {
    animation: spin 2s linear infinite;
    /* 其他样式，比如宽高、颜色等 */
}

.spin-element2 {
    animation: spinf 2s linear infinite;
    /* 其他样式，比如宽高、颜色等 */
}

.spin-element3 {
    animation: spin 3s linear infinite;
    /* 其他样式，比如宽高、颜色等 */
}

.swiper-container {
    height: 150px;
    width: 80%;
    position: absolute !important;
    margin: auto;
    bottom: 100px;
    left: 0;
    right: 0;
    transition: .3s;
}

.swiper-slide {
    position: relative;
    height: 100%;
    width: 264px;
    padding: 0 35px;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-color: #F7F8FA; */
}

.swiper-slide-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
}

.swiper-button-next,
.swiper-button-prev {
    height: 100px;
    /* content: '' !important; */
    /* background-image: url(https://cdn.bosscaigou.com/threejs/imgs/yasuo_image%20%283%29.webp); */
    background-size: 100% 100%;
    transform: translateY(-25%) !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    /* content: '' !important; */
    color: #FFF !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.swiper-button-prev {
    left: 0 !important;
    transform: translateY(-25%) !important;
}

.swiper-button-next {
    right: 0 !important;
}
</style>
<script>
import JSZip from 'jszip';
import { jumpTo, loadCss } from '@/utils/myutils';
import * as THREE from 'three';
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { CSS3DRenderer, CSS3DSprite } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import TWEEN from "three/examples/jsm/libs/tween.module.js";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { MapControls } from 'three/examples/jsm/controls/MapControls.js';
import { getFileSize } from '@/utils/myutils';
import { modelInfo } from '@/api';
import { hex_md5 } from '@/utils/md5';
// 创建场景
const scene = new THREE.Scene();
// 创建渲染器
var renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    logarithmicDepthBuffer: true
});
// 添加渲染标签到网站
// document.body.appendChild(renderer.domElement);
// 定义相机，控制器，平移控制器
var camera, controls, transformControl;

const pointer = new THREE.Vector2();

const point = new THREE.Vector3();

const raycaster = new THREE.Raycaster();

const raycaster2 = new THREE.Raycaster();

let splineHelperObjects = [];

let cssRenderer = new CSS3DRenderer();

const highlightMaterial = new THREE.LineBasicMaterial({
    color: 0xffffff,
    linewidth: 5,
    background: 0xffffff,
    transparent: true,
    opacity: 1
});

const params = {
    threshold: 0,
    strength: 1,
    radius: 0,
    exposure: 1,
};

const ARC_SEGMENTS = 200;

let splines = [];

export default {
    data() {
        return {
            applyError: false,
            formLabelWidth: '110px',
            objData: null,
            passDialog: false,
            passStr: '',
            tagState: true,
            audioState: false,
            mp3Audio: null,
            sysMode: 1,
            swiper: null,
            swiperState: false,
            swiperList: [{
                id: 1,
                title: 'A设备',
                imgUrl: 'https://cdn.bosscaigou.com/threejs/imgs/2ad7126dd78c92a1ff0a1ff2e84aeaa.png',
                from: [280, 50, -30],
                to: [280, 0, -30]
            }, {
                id: 2,
                title: 'B设备',
                imgUrl: 'https://cdn.bosscaigou.com/threejs/imgs/f83682157ca165b69fbce853767ae49.png',
                from: [220, 40, 34],
                to: [220, 0, 34]
            }, {
                id: 3,
                title: 'C设备',
                imgUrl: 'https://cdn.bosscaigou.com/threejs/imgs/a84ab46fb156190a8df25ad021742ee.png',
                from: [0, 40, 50],
                to: [0, 0, 50]
            }, {
                id: 4,
                title: 'D设备',
                imgUrl: 'https://cdn.bosscaigou.com/threejs/imgs/505ac6b30803b6b266a8fea694d4444.png',
                from: [0, 40, -50],
                to: [0, 0, -50]
            }, {
                id: 5,
                title: 'D设备',
                imgUrl: 'https://cdn.bosscaigou.com/threejs/imgs/21c222b4bf39b7d406a2423dc20b509.png',
                from: [0, 40, 100],
                to: [0, 0, 100]
            }],
            // 场景
            // threeSence: null,
            // 绘制绑定标签
            threeDom: null,
            // 相机
            threeCamera: null,
            // 三维向量存储相机位置信息
            velocity: new THREE.Vector3(),
            // 二维向量
            threeVector: null,
            // 渲染器
            threeRenderer: null,
            // 图片加载器
            textureLoader: null,
            // 第一人称控制器
            // threeControl: null,
            // 相交射线
            threeRaycaster: null,
            // 模型加载器
            objLoader: null,
            // 上次渲染时间
            prevTime: 0,
            // 模型列表
            objList: [],
            // 镜头光源
            followLight: null,
            // 移动速度
            speed: 0.3,
            // 是否有加速
            accelerated: false,
            // 能否跳跃
            canJump: true,
            // 前后左右
            forward: false,

            back: false,

            left: false,

            right: false,

            btnShow: true,
            // 模型加载进度
            loadProgress: 0,

            positionList: [
                [10, 0, 0],
                [-10, 0, 0]
            ],

            rotateState: false,

            selectObject: null,

            selectedMaterial: null,

            composer: null,

            effectComposer: null,

            renderScene: null,

            effectCopy: null,

            nowLayer: 0,

            clickContainer: {},

            visitState: false,

            tagInfo: []

        }
    },
    mounted() {
        // 初始化THREE
        this.initThree();
        this.initSwiper();
        // 播放背景音乐
        // this.mp3Play();
    },
    methods: {
        goToList() {
            jumpTo(this, "预览列表", {}, {});
        },
        goTo() {
            jumpTo(this, "工厂主页", {}, {});
        },
        checkPass() {

            if (hex_md5(this.passStr) == this.objData.pass) {

                this.initObj4(this.objData.url);

                this.passDialog = false;

            } else {

                this.$notify({
                    title: '警告',
                    message: '密码输入错误，请重新输入！',
                    type: 'warning'
                });

            }

        },
        swiperFuc() {
            console.log(this.swiperState)
            this.swiperState = !this.swiperState;
        },
        controlsLock() {
            controls.lock();
        },
        changeMode(index) {
            this.sysMode = index;
            this.initControl(this.sysMode);
            if (this.sysMode == 1) {
                this.flyTo({
                    position: [0, 200, 0],
                    controls: [0, 0, 0],
                    done: () => {

                    }
                })
            } else if (this.sysMode == 2) {
                this.flyTo({
                    position: [0, 200, 0],
                    controls: [0, 0, 0],
                    done: () => {

                    }
                })
            }
        },
        initSwiper() {
            let _this = this;
            this.swiper = new Swiper(".swiper-container", {
                loop: true, // 无缝
                autoplay: { //自动开始
                    delay: 8000, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                paginationClickable: true,
                fade: { crossFade: true },
                slidesPerView: 5, // 一组三个
                spaceBetween: 0, // 间隔
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
                observer: true,
                observeParents: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 分页器可以点击
                },
                on: {
                    click: (e) => {

                        console.log(e)

                        var clickedSlide = e.target.closest('.swiper-slide');
                        if (clickedSlide) {
                            console.log(clickedSlide, _this.swiper.slides)
                            // 获取点击元素的index
                            var index = Array.from(_this.swiper.slides).indexOf(clickedSlide);
                            console.log('Clicked slide index:', index % 5);
                            this.swiper && this.swiper.slideTo(index % 5, 300);
                        }

                    },
                    transitionEnd: function () {
                        if (_this.swiperState) {
                            console.log(this.realIndex)
                            _this.flyTo({
                                position: _this.swiperList[this.realIndex].from || [0, 200, 0],
                                controls: _this.swiperList[this.realIndex].to || [0, 0, 0],
                                done: () => {

                                }
                            })
                        }
                    }
                },
            })
        },
        // 切换镜头视角
        changeCamera() {

        },
        // 切换渲染层
        changeLayer() {

            this.nowLayer = this.nowLayer == 0 ? 1 : 0;

            camera.layers.set(this.nowLayer);

        },
        // 初始化系统
        initThree2() {

            let _this = this;
            // 初始化绑定标签
            this.threeDom = this.$refs['threeShow'];
            // 初始化图片加载器
            this.textureLoader = new THREE.TextureLoader();
            // 初始化背景
            // scene.background = new THREE.Color(0x00000000);
            renderer.setAnimationLoop(this.render2);

            document.body.appendChild(renderer.domElement);

            raycaster = new THREE.Raycaster();

            this.threeVector = new THREE.Vector2();

            renderer.setClearAlpha(0);
            // 初始化地面
            // this.initFloor2();
            // 初始化光源
            this.initLight2();
            // 初始化相机
            this.initCamera();
            // 初始化渲染
            this.initRender();

            this.initSky();

            this.initObj3();
            // 初始化控制器
            this.initControls();
            // 初始化鼠标事件
            // this.threeDom.addEventListener('pointermove', e => {
            //     _this.onPointerMove(e);
            // });
            renderer.domElement.addEventListener('click', this.onMouseClick, false);
            // 调整窗口大小时重置渲染器和相机
            window.addEventListener('resize', () => {

                camera.aspect = window.innerWidth / window.innerHeight;

                camera.updateProjectionMatrix();

                renderer.setSize(window.innerWidth, window.innerHeight);

                cssRenderer.setSize(window.innerWidth, window.innerHeight);

            });
            // 存储控制器状态
            this.saveCurrentState();

            this.initSelect();
            // 初始化CSS2D渲染器：用于渲染模型的2D或者3D标签
            cssRenderer.setSize(window.innerWidth, window.innerHeight);
            // 设置position和top属性，把转化后的css2D标签和原来的页面重叠
            cssRenderer.domElement.style.position = 'absolute';

            cssRenderer.domElement.style.pointerEvents = 'none';

            // cssRenderer.center.set(0, 1,0);

            cssRenderer.domElement.style.top = 0;

            this.threeDom.appendChild(cssRenderer.domElement);



            /*
                        // 设置选中设备辉光层
                        //设置光晕
                        this.effectComposer = new EffectComposer(renderer);        //效果组合器
            
                        this.effectComposer.setSize(window.innerWidth, window.innerHeight);
                        //传入了CopyShader着色器，用于拷贝渲染结果
                        this.effectCopy = new ShaderPass(CopyShader);
            
                        this.effectCopy.renderToScreen = true;
                        //创建通道
                        this.renderScene = new RenderPass(scene, camera);
                        //创建通道
                        this.bloomPass = new UnrealBloomPass(
                            //参数一：泛光覆盖场景大小，二维向量类型
                            new THREE.Vector2(window.innerWidth, window.innerHeight),
                            //参数二：bloomStrength 泛光强度，值越大明亮的区域越亮，较暗区域变亮的范围越广
                            1.5,
                            //参数三：bloomRadius 泛光散发半径
                            0.1,
                            //参数四：bloomThreshold 泛光的光照强度阈值，如果照在物体上的光照强度大于该值就会产生泛光
                            0.85
                        );
            
                        this.bloomPass.renderToScreen = true;
                        //赋值间接对象
                        var params = {
                            exposure: 1,
                            bloomStrength: 1.5,
                            bloomThreshold: 0,
                            bloomRadius: 0,
                        };
            
                        this.bloomPass.threshold = params.bloomThreshold;
            
                        this.bloomPass.strength = params.bloomStrength;
            
                        this.bloomPass.radius = params.bloomRadius;
            
                        this.effectComposer.addPass(this.renderScene);
            
                        this.effectComposer.addPass(this.effectCopy);
            
                        this.effectComposer.addPass(this.bloomPass);
            
                        this.effectComposer.render();
            
            */

        },
        // 鼠标点击事件
        onMouseClick(event) {

            console.log(12312312)

            this.threeVector.x = (event.clientX / window.innerWidth) * 2 - 1;

            this.threeVector.y = - (event.clientY / window.innerHeight) * 2 + 1;

            // 更新raycaster
            raycaster.setFromCamera(this.threeVector, camera);

            // 计算物体和鼠标的交点
            const intersects = raycaster.intersectObjects(scene.children, false);

            // 检查是否有交点，并且这个物体就是我们期望的
            for (const intersect of intersects) {

                console.log(intersect.object)

                // if (this.clickContainer['A栋'].includes(intersect.object.name)) {

                //     console.log('点击了期望的组件');
                //     // 执行相应操作
                //     break; // 找到就跳出循环

                // }

            }

        },
        // 初始化选中功能
        initSelect() {

            renderer.domElement.style.touchAction = 'none';

            renderer.domElement.addEventListener('pointermove', this.onPointerMove, false);

        },
        initClickContainer(name, obj, state) {

            obj.children.forEach((item, index) => {

                if (state) {

                    if (item.name == name) {

                        if (this.clickContainer[name] == null) this.clickContainer[name] = [];

                        this.clickContainer[name].push(name);

                        if (item.children != null) this.initClickContainer(name, item, false);

                    } else if (item.children != null) {

                        this.initClickContainer(name, item, true);

                    }

                } else {

                    this.clickContainer[name].push(item.name);

                    if (item.children != null) {

                        this.initClickContainer(name, item, false);

                    }

                }

            });


        },
        rotateClickFuc() {

            controls.autoRotate = !controls.autoRotate;

            this.rotateState = controls.autoRotate;

            console.log(controls.autoRotate)

        },
        // 初始化相机
        initCamera() {

            camera = new THREE.PerspectiveCamera(
                75,
                this.threeDom.offsetWidth / this.threeDom.offsetHeight,
                0.01,
                2000
            );

            // camera.layers.enable(0);

            camera.position.set(0, 200, 200);

            camera.lookAt(0, 0, 0);

        },
        //相机跳转
        flyTo(option) {
            option.position = option.position || [];
            option.controls = option.controls || [];
            option.duration = option.duration || 1000;
            option.easing = option.easing || TWEEN.Easing.Linear.None;

            const curPosition = camera.position;
            const curControls = controls.target || controls.object.position;

            const tween = new TWEEN.Tween({
                x1: curPosition.x,
                y1: curPosition.y,
                z1: curPosition.z,
                x2: curControls.x,
                y2: curControls.y,
                z2: curControls.z,
            })
                .to(
                    {
                        x1: option.position[0],
                        y1: option.position[1],
                        z1: option.position[2],
                        x2: option.controls[0],
                        y2: option.controls[1],
                        z2: option.controls[2],
                    },
                    option.duration
                )
                .easing(option.easing);

            tween.onStart(() => {
                controls.enabled = false;
                if (option.start instanceof Function) {
                    option.start();
                }
            });
            tween.onUpdate((object) => {
                controls.enabled = false;
                camera.position.set(object.x1, object.y1, object.z1);
                controls.target.set(object.x2, object.y2, object.z2);
                if (option.update instanceof Function) {
                    option.update();
                }
            });
            tween.onComplete(() => {
                controls.enabled = true;
                if (option.done instanceof Function) {
                    option.done();
                }
            });
            tween.onStop(() => {
                controls.enabled = true;
                if (option.stop instanceof Function) {
                    option.stop();
                }
            });
            tween.start();
            return tween;
        },
        /**
         * 创建指向线的函数，用于在 Three.js 场景中创建指向线
         * @param {THREE.Vector3} start 指向线的起点坐标
         * @param {THREE.Vector3} end 指向线的终点坐标
         * @param {string} color 指向线的颜色，格式为 CSS 颜色值，例如 "#ff0000" 表示红色
         * @param {number} width 指向线的宽度
         * @param {string} background 背景贴图的路径
         */
        // 创建指向线的函数
        createPointerLine(start, end, color, width, background) {
            // 创建指向线的几何体
            const geometry = new THREE.BufferGeometry();
            const vertices = new Float32Array([
                start.x,
                start.y,
                start.z,
                end.x,
                end.y,
                end.z,
            ]);
            geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
            // 创建指向线的材质
            const material = new THREE.LineBasicMaterial({
                color: color, // 指定线的颜色
                linewidth: width, // 设置线的宽度
            });

            // 创建指向线对象
            const line = new THREE.Line(geometry, material);

            // 创建一个 Object3D 用于存放线
            const pointerGroup = new THREE.Object3D();
            pointerGroup.add(line);

            // 计算线的方向向量
            const direction = new THREE.Vector3().copy(end).sub(start).normalize();

            // 计算线头和线尾的位置, 设置圆饼偏移量
            const headPosition = new THREE.Vector3()
                .copy(start)
                .addScaledVector(direction, 0);
            const tailPosition = new THREE.Vector3()
                .copy(end)
                .addScaledVector(direction, 0);

            //贴图
            // 使用 TextureLoader 加载贴图
            // const yxTextureLoader = new THREE.TextureLoader();
            // const yxTexture = yxTextureLoader.load("../../public/label/yuandianTop.png"); // 加载贴图
            // 创建线头圆饼
            const headGeometry = new THREE.CircleGeometry(.1, 32);
            const headMaterial = new THREE.MeshBasicMaterial({
                color: color, // 指定线尾的颜色
                side: THREE.DoubleSide, // 设置双面可见
                // map: yxTexture, // 设置贴图 
                // transparent: true, // 设置材质为透明
            });
            const headMesh = new THREE.Mesh(headGeometry, headMaterial);
            headMesh.position.copy(headPosition);

            // 创建线尾圆饼
            const tailGeometry = new THREE.CircleGeometry(0.1, 32);
            const tailMaterial = new THREE.MeshBasicMaterial({
                color: color,
                side: THREE.DoubleSide,
            });

            const tailMesh = new THREE.Mesh(tailGeometry, tailMaterial);
            tailMesh.position.copy(tailPosition);

            // 使用 TextureLoader 加载背景纹理图片
            const backgroundMaterial = new THREE.MeshBasicMaterial({
                // map: backgroundTexture,  // 设置背景的纹理贴图
                side: THREE.DoubleSide,  // 设置双面可见
                color: color
            });
            const backgroundGeometry = new THREE.PlaneGeometry(
                width,
                end.distanceTo(start), // 背景的长度，即线段的长度
                1,
                1
            );
            const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
            // 设置背景的位置为线段的中点
            const midpoint = new THREE.Vector3().copy(start).add(end).multiplyScalar(0.5);
            backgroundMesh.position.copy(midpoint); // 设置背景的朝向
            // 设置背景的朝向（垂直方向上朝向相机位置）,计算垂直方向上背景朝向的点，即与相机位置相同高度的点
            const verticalLookAtPoint = new THREE.Vector3(
                camera.position.x,
                backgroundMesh.position.y,
                camera.position.z
            );
            backgroundMesh.lookAt(verticalLookAtPoint);
            // backgroundMesh.up.set(0, 1, 0);

            // 将线和背景添加到场景中
            this.updateOrientation = () => {
                headMesh.lookAt(camera.position); // 使线头始终朝向相机
                tailMesh.lookAt(camera.position);  // 使线尾始终朝向相机
                // 获取相机的水平方向向量
                const cameraDirection = camera
                    .getWorldDirection(new THREE.Vector3())
                    .normalize();
                const cameraHorizontalDirection = new THREE.Vector3(
                    cameraDirection.x,
                    0,
                    cameraDirection.z
                ).normalize();
                // 让背景朝向相机的水平方向
                backgroundMesh.lookAt(
                    backgroundMesh.position.clone().add(cameraHorizontalDirection)
                );
            }
            scene.add(headMesh);
            scene.add(backgroundMesh);
            scene.add(tailMesh);
        },
        jumpTo(p, c, t, cb) {
            let _this = this;
            _this.visitState && setTimeout(() => {
                _this.visitState && _this.flyTo({
                    position: p,
                    controls: c,
                    done: () => {
                        cb();
                    }
                })
            }, t);
        },
        // 切换镜头
        changeCamera() {
            let _this = this;
            console.log(_this)
            _this.visitState = !_this.visitState;
            _this.visitState && _this.flyTo({
                position: [280, 50, -30],
                controls: [280, 0, -30],
                done: () => {
                    this.jumpTo([230, 50, -30], [230, 0, -30], 2000, () => {

                        this.jumpTo([230, 50, 30], [230, 0, 30], 2000, () => {

                            this.jumpTo([175, 50, 30], [175, 0, 30], 2000, () => {

                                this.jumpTo([175, 50, -10], [175, 0, -10], 2000, () => {

                                    this.jumpTo([165, 50, -10], [165, 0, -10], 2000, () => {

                                        this.jumpTo([165, 50, 20], [165, 0, 20], 2000, () => {

                                            this.jumpTo([105, 50, 20], [105, 0, 20], 2000, () => {

                                                this.jumpTo([105, 50, 10], [105, 0, 10], 2000, () => {

                                                    this.jumpTo([155, 50, 10], [155, 0, 10], 2000, () => {

                                                        this.jumpTo([155, 50, -5], [155, 0, -5], 2000, () => {

                                                            this.jumpTo([55, 50, -5], [55, 0, -5], 2000, () => {

                                                                this.jumpTo([-25, 50, -5], [-25, 0, -5], 2000, () => {

                                                                    this.jumpTo([-25, 50, 15], [-25, 0, 15], 2000, () => {

                                                                        this.jumpTo([-155, 50, 15], [-155, 0, 15], 200, () => {

                                                                            this.jumpTo([-155, 50, -25], [-155, 0, -25], 2000, () => {

                                                                                this.jumpTo([-155, 50, -25], [-155, 0, -25], 2000, () => {

                                                                                    _this.visitState = !_this.visitState;

                                                                                    this.changeCamera();

                                                                                })

                                                                            })

                                                                        })

                                                                    })

                                                                })

                                                            })

                                                        })

                                                    })

                                                })

                                            })

                                        })

                                    })

                                })

                            })

                        });

                    });

                }
            });
        },
        // 初始化渲染
        initRender() {

            renderer.setSize(window.innerWidth, window.innerHeight);

            renderer.outputEncoding = THREE.sRGBEncoding;

            this.threeDom.appendChild(renderer.domElement);

        },
        // 存储控制器状态
        saveCurrentState() {
            controls.saveState();
        },
        // 恢复控制器状态
        reset() {
            // 关闭控制器旋转
            controls.autoRotate = false;

            this.rotateState = false;
            // 重置控制器
            this.flyTo({
                position: [0, 200, 0],
                duration: 800,
                controls: [0, 0, 0],
                done: () => {

                }
            })

        },
        // 初始化控制器
        initControls() {

            controls = new OrbitControls(camera, renderer.domElement);
            // 旋转速度
            controls.autoRotateSpeed = 5;
            // 控制阻尼
            controls.enableDamping = true;
            // 阻尼强度
            controls.dampingFactor = 0.4;

            controls.minZoom = .5;

            controls.maxZoom = 2;

            controls.minDistance = 5;
            controls.maxDistance = 90;

            // 限制垂直旋转范围
            controls.minPolarAngle = 0;
            controls.maxPolarAngle = Math.PI / 2;

            transformControl = new TransformControls(camera, renderer.domElement);

            transformControl.addEventListener('change', () => {
                console.log('模型拖动')
            });

            transformControl.addEventListener('dragging-changed', function (event) {
                controls.enabled = !event.value
            });

            transformControl.addEventListener('objectChange', param => {
                this.updateSplineOutline()
            });

            scene.add(transformControl);

        },
        setStatus(object) {
            // 存在就重置对象
            if (this.selectObject) this.restoreObjectStatus();
            // 初始化选中对象
            this.selectObject = object;
            // 替换选中材质
            this.selectedMaterial = object.material;
            // 替换选中材质
            this.selectObject.material = highlightMaterial;

        },
        restoreObjectStatus() {
            if (this.selectObject) {
                this.selectObject.material = this.selectedMaterial;
            }
            this.selectObject = null;
            this.selectedMaterial = null;
        },
        // 鼠标移动
        onPointerMove(event) {

            pointer.x = (event.clientX / window.innerWidth) * 2 - 1;

            pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;


            // raycaster.setFromCamera(pointer, camera);

            // const intersects = raycaster.intersectObjects(this.objList, true);

            // if (intersects.length > 0) {

            //     const object = intersects[0].object;

            //     if (this.selectObject != object) {

            //         this.setStatus(object);

            //     }

            //     // object.material = highlightMaterial;

            //     // if (object.geometry) {

            //     //     let edges = new THREE.EdgesGeometry(object.geometry);

            //     //     let line = new THREE.LineSegments(edges, highlightMaterial);

            //     //     object.add(line);

            //     // }

            // }
        },
        // 更新连线
        updateSplineOutline() {
            for (let k = 0; k < splines.length; k++) {
                const spline = splines[k]
                const splineMesh = spline.mesh
                const position = splineMesh.geometry.attributes.position
                for (let i = 0; i < ARC_SEGMENTS; i++) {
                    const t = i / (ARC_SEGMENTS - 1)
                    spline.getPoint(t, point)
                    position.setXYZ(i, point.x, point.y, point.z)
                }
                position.needsUpdate = true
            }
        },
        render2() {
            // requestAnimationFrame(this.render2.bind(this));
            controls.update();
            // 渲染器清除颜色、深度或模板缓存. 此方法将颜色缓存初始化为当前颜色
            renderer.clear();
            // if (this.rotateState) {
            //     const timer = Date.now() * 0.0003;
            //     camera.position.x = Math.sin(timer) * 0.5;
            //     camera.position.z = Math.cos(timer) * 0.5;
            //     // camera.lookAt(0, 0, 0);
            // }



            /*

            raycaster.setFromCamera(pointer, camera);

            const intersects = raycaster.intersectObjects(this.objList, true);

            if (intersects.length > 0) {

                const object = intersects[0].object;

                if (this.selectObject != object) {

                    this.setStatus(object);

                }

            } else {

                this.restoreObjectStatus();

            }

            */

            TWEEN.update();

            cssRenderer && cssRenderer.render(scene, camera);

            // this.effectComposer && this.effectComposer.render();

            renderer.clearDepth();

            this.updateOrientation && this.updateOrientation();

            renderer && renderer.render(scene, camera);
        },
        animate() {

            const time = performance.now() / 3000;

            // spotLight.position.x = Math.cos(time) * 2.5;
            // spotLight.position.z = Math.sin(time) * 2.5;

            // lightHelper.update();

            renderer.render(scene, camera);

        },
        addMarkModel() {
            for (let i = 0; i < this.positionList.length; i++) {
                let position = []

                let obj1 = this.creatSpot(this.positionList[i], i)
                let obj2 = this.creatHtml(i)

                setTimeout(() => {
                    position.push(obj1.position)
                    position.push(obj2.position)
                    this.creatLine(position)
                }, 2000)
            }
        },
        creatSpot(positionArr, index) {
            const spherGeometry = new THREE.SphereGeometry(3, 32, 16)
            const spherMaterial = new THREE.MeshLambertMaterial({ color: 'red' })
            const sphere = new THREE.Mesh(spherGeometry, spherMaterial)
            sphere.position.set(positionArr[0], positionArr[1], positionArr[2])
            sphere.scale.set(0.2, 0.2, 0.2)
            splineHelperObjects.push(sphere)
            scene.add(sphere)
            return sphere
        },
        creatHtml(index, spot) {
            const spherGeometry = new THREE.BoxGeometry(15, 15, 15)
            const spherMaterial = new THREE.MeshPhongMaterial({
                transparent: true,
                opacity: 1,
                color: 'green'
            })
            const sphere = new THREE.Mesh(spherGeometry, spherMaterial)

            sphere.position.x = Math.random() * 10
            sphere.position.y = Math.random() * 20
            sphere.position.z = Math.random() * 15
            sphere.scale.set(0.2, 0.2, 0.2)
            splineHelperObjects.push(sphere)
            scene.add(sphere)

            return sphere
        },
        creatLine(position) {
            var curve = new THREE.CatmullRomCurve3(position)
            var points = curve.getPoints(ARC_SEGMENTS)
            var geometry = new THREE.BufferGeometry().setFromPoints(points)
            var material = new THREE.LineBasicMaterial({ color: 0xff0000 })

            curve.mesh = new THREE.LineSegments(geometry, material)
            scene.add(curve.mesh)
            splines.push(curve)
        },
        mp3Play(mp3) {
            // 初始化音乐播放器
            this.mp3Audio = new Audio('https://cdn.bosscaigou.com' + mp3);
            // 自动播放
            this.mp3Audio.loop = true;
            if (!this.mp3Audio) {
                this.$message.error("未设置背景音乐！");
                return;
            }
            this.audioState = this.mp3Audio.paused;
            if (this.mp3Audio?.paused) {
                this.mp3Audio.play();
            } else {
                this.mp3Audio.pause();
            }
        },
        initThree() {
            // 初始化绑定标签
            this.threeDom = this.$refs['threeShow'];
            // 初始化背景
            scene.background = new THREE.Color(0xFFFFFF);
            // 初始化相机
            this.initCamera();
            // 渲染器开启阴影计算
            renderer.shadowMap.enabled = true;
            // 设置渲染器大小
            renderer.setSize(this.$refs.threeShow.clientWidth, this.$refs.threeShow.clientHeight);
            // 将渲染器添加到容器中
            this.$refs.threeShow.appendChild(renderer.domElement);
            // 初始化控制器
            this.initControl(this.sysMode);
            // 设置动作循环
            renderer.setAnimationLoop(this.render);
            // 初始化相交射线
            // raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1, 0), 0, 2);
            // 初始化图片加载器
            this.textureLoader = new THREE.TextureLoader();
            // 初始化天空
            // this.initSky();
            // 初始化地面
            this.initFloor();
            // 鼠标按下事件
            renderer.domElement.addEventListener('mousedown', function (event) {

                renderer.domElement.style.cursor = 'grabbing';

            });

            // 鼠标抬起事件
            renderer.domElement.addEventListener('mouseup', function (event) {

                renderer.domElement.style.cursor = 'grab';

            });
            // this.initFloor3();
            // 初始化光源
            this.initLight();
            // 初始化工厂模型
            // this.initObj();
            this.initWebObj4();

            // this.initObj5();
            // this.boxMesh();
            // 初始化监听键盘事件
            this.handleKey();
            // 渲染
            this.render();
            // 调整窗口大小时重置渲染器和相机
            window.addEventListener('resize', () => {
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(window.innerWidth, window.innerHeight);
            });
            // 初始化CSS2D渲染器：用于渲染模型的2D或者3D标签
            cssRenderer.setSize(window.innerWidth, window.innerHeight);
            // 设置position和top属性，把转化后的css2D标签和原来的页面重叠
            cssRenderer.domElement.style.position = 'absolute';

            cssRenderer.domElement.style.pointerEvents = 'none';

            // cssRenderer.center.set(0, 1,0);

            cssRenderer.domElement.style.top = 0;

            this.threeDom.appendChild(cssRenderer.domElement);

        },
        // 初始化天空
        initSky() {
            //添加天空
            var urls = [
                './obj/sky2/left.jpg',
                './obj/sky2/right.jpg',
                './obj/sky2/top.jpg',
                './obj/sky2/bottom.jpg',
                './obj/sky2/front.jpg',
                './obj/sky2/back.jpg'
            ]
            // 初始化贴图加载器
            var cubeLoader = new THREE.CubeTextureLoader();
            // 设置天空
            scene.background = cubeLoader.load(urls)

        },
        // 初始化地面
        initFloor() {
            // 添加地板
            let planeGeomotry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
            // 颜色贴图
            let mapImg = this.textureLoader.load('./img/three/aaa.webp');
            // ao贴图
            let aoImg = this.textureLoader.load('./img/three/aaa.webp');
            // 凹凸贴图
            let bumpImg = this.textureLoader.load('./img/three/aaa.webp');
            // 位移贴图
            let dispImg = this.textureLoader.load('./img/three/aaa.webp');
            // 法线贴图
            let nrmImg = this.textureLoader.load('./img/three/aaa.webp');
            // 设置地面平铺，THREE.RepeatWrapping：平铺重复。
            mapImg.wrapS = mapImg.wrapT = THREE.RepeatWrapping;
            // 设置重复次数
            mapImg.repeat.set(1, 1);
            // 地面材质
            let planeMeshStandardMaterial = new THREE.MeshStandardMaterial({
                color: 0xffffff,
                map: mapImg,
                side: THREE.DoubleSide,// 双面贴图
                aoMap: aoImg,
                bumpMap: bumpImg,
                displacementMap: dispImg,
                normalMap: nrmImg
            });


            const material = new THREE.ShaderMaterial({
                vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
                fragmentShader: `
    varying vec2 vUv;
    void main() {
      vec2 center = vec2(0.5, 0.5);
      float distance = distance(vUv, center);
      vec3 colorA = vec3(0.051, 0.737, 0.475); // 中心颜色
      vec3 colorB = vec3(1,1,1); // 边缘颜色
      float radius = 0.25; // 渐变半径
 
      if (distance < radius) {
        float ratio = (radius - distance) / radius;
        vec3 color = mix(colorB, colorA, ratio);
        gl_FragColor = vec4(color, 1.0);
      } else {
        gl_FragColor = vec4(colorB, .2);
      }
    }
  `,
            });


            // 网格
            let plane = new THREE.Mesh(planeGeomotry, material);
            // 物体投射阴影
            plane.castShadow = true;
            // 物体接收阴影
            // plane.receiveShadow = true;
            // 旋转地板至水平(90°)
            plane.rotation.x = -Math.PI / 2;
            //设置地板位置
            plane.position.set(0, -7, 0);
            // 添加地板到场景中
            scene.add(plane);

        },
        // 初始化地面
        initFloor3() {
            // 添加地板
            let planeGeomotry = new THREE.PlaneGeometry(680, 200, 50, 50);
            // 颜色贴图
            let mapImg = this.textureLoader.load('./img/three/color.jpg');
            // ao贴图
            let aoImg = this.textureLoader.load('./img/three/color.jpg');
            // 凹凸贴图
            let bumpImg = this.textureLoader.load('./img/three/color.jpg');
            // 位移贴图
            let dispImg = this.textureLoader.load('./img/three/color.jpg');
            // 法线贴图
            let nrmImg = this.textureLoader.load('./img/three/color.jpg');
            // 设置地面平铺，THREE.RepeatWrapping：平铺重复。
            mapImg.wrapS = mapImg.wrapT = THREE.RepeatWrapping;
            // 设置重复次数
            mapImg.repeat.set(80, 30);
            // 地面材质
            let planeMeshStandardMaterial = new THREE.MeshStandardMaterial({
                map: mapImg,
                side: THREE.DoubleSide,// 双面贴图
                aoMap: aoImg,
                bumpMap: bumpImg,
                displacementMap: dispImg,
                normalMap: nrmImg
            });
            // 网格
            let plane = new THREE.Mesh(planeGeomotry, planeMeshStandardMaterial);
            // 物体投射阴影
            plane.castShadow = true;
            // 物体接收阴影
            // plane.receiveShadow = true;
            // 旋转地板至水平(90°)
            plane.rotation.x = -Math.PI / 2;
            //设置地板位置
            plane.position.set(0, -6, 0);
            // 添加地板到场景中
            scene.add(plane);

        },
        // 初始化地面
        initFloor2() {
            // 添加地板
            let planeGeomotry = new THREE.PlaneGeometry(25, 25, 5, 5);
            // 颜色贴图
            let mapImg = this.textureLoader.load('./img/three/color.jpg');
            // ao贴图
            let aoImg = this.textureLoader.load('./img/three/color.jpg');
            // 凹凸贴图
            let bumpImg = this.textureLoader.load('./img/three/color.jpg');
            // 位移贴图
            let dispImg = this.textureLoader.load('./img/three/color.jpg');
            // 法线贴图
            let nrmImg = this.textureLoader.load('./img/three/color.jpg');
            // 设置地面平铺，THREE.RepeatWrapping：平铺重复。
            mapImg.wrapS = mapImg.wrapT = THREE.RepeatWrapping;
            // 设置重复次数
            mapImg.repeat.set(30, 30);
            // 地面材质
            let planeMeshStandardMaterial = new THREE.MeshStandardMaterial({
                map: mapImg,
                side: THREE.DoubleSide,// 双面贴图
                aoMap: aoImg,
                bumpMap: bumpImg,
                displacementMap: dispImg,
                normalMap: nrmImg
            });
            // 网格
            let plane = new THREE.Mesh(planeGeomotry, planeMeshStandardMaterial);
            // 物体投射阴影
            plane.castShadow = true;
            // 物体接收阴影
            // plane.receiveShadow = true;
            // 旋转地板至水平(90°)
            plane.rotation.x = -Math.PI / 2;
            //设置地板位置
            plane.position.set(0, 0, 0);
            // 添加地板到场景中
            scene.add(plane);

        },
        // 初始化控制器(type[0：轨迹球控制器，1：第一人称控制器，2：飞行控制器，3：轨道控制器])
        initControl(type) {

            const _this = this;

            switch (type) {

                case 1:

                    controls && controls.dispose(); // 清理相关资源

                    controls = new OrbitControls(camera, renderer.domElement);
                    // 旋转速度
                    controls.autoRotateSpeed = 5;
                    // 控制阻尼
                    controls.enableDamping = true;
                    // 阻尼强度
                    controls.dampingFactor = 0.4;

                    controls.minZoom = .5;

                    controls.maxZoom = 2;

                    controls.minDistance = 15;

                    controls.maxDistance = 250;

                    // 限制垂直旋转范围
                    controls.minPolarAngle = 0;

                    controls.maxPolarAngle = Math.PI / 2;

                    transformControl = new TransformControls(camera, renderer.domElement);

                    transformControl.addEventListener('change', () => {
                        console.log('模型拖动')
                    });

                    transformControl.addEventListener('dragging-changed', function (event) {
                        if (_this.sysMode == 1) {
                            controls.enabled = !event.value
                        }
                    });

                    transformControl.addEventListener('objectChange', param => {
                        if (_this.sysMode == 1) {
                            this.updateSplineOutline()
                        }
                    });

                    scene.add(transformControl);

                    break;

                case 0:

                    controls && controls.dispose(); // 清理相关资源
                    // 初始化第一人称控制器
                    controls = new PointerLockControls(camera, renderer.domElement);
                    // 将控制器添加到场景当中
                    scene.add(controls.getObject());

                    let _this = this;

                    controls.addEventListener('unlock', () => {

                        setTimeout(() => {

                            _this.btnShow = true;

                        }, 1000);

                    });

                    controls.addEventListener('lock', () => {

                        setTimeout(() => {

                            _this.btnShow = false;

                        }, 1000);

                    });

                    break;

                case 2:

                    controls && controls.dispose(); // 清理相关资源

                    controls = new MapControls(camera, renderer.domElement);

                    renderer.domElement.style.cursor = 'grab';

                    break;

                case 3:
                    // 清理相关资源
                    controls && controls.dispose();

                    controls = new FlyControls(camera, renderer.domElement);
                    // 设置移动速度
                    controls.movementSpeed = 100;

                    controls.domElement = renderer.domElement;
                    // 设置旋转速度
                    controls.rollSpeed = Math.PI / 6;
                    // 不自动向前移动
                    controls.autoForward = false;
                    // 鼠标拖动时控制视角
                    controls.dragToLook = false;

                    break;

            }

        },
        // 初始化光源
        initLight() {
            // 添加环境光
            let AmbientLight = new THREE.AmbientLight(0xFFFFFF, .8);

            // AmbientLight.castShadow = true;
            // 将环境光添加到场景中
            scene.add(AmbientLight);
            // 添加直线光
            let directionalLight = new THREE.DirectionalLight(0xFFFFFF, 5);
            // 创建阴影映射相关参数
            directionalLight.shadow.mapSize.width = 4048; // 阴影映射纹理的宽度
            directionalLight.shadow.mapSize.height = 4048; // 阴影映射纹理的高度
            directionalLight.shadow.camera.near = -50; // 阴影映射相机的近剪裁面
            directionalLight.shadow.camera.far = 200; // 阴影映射相机的远剪裁面

            directionalLight.shadow.camera.top = 100;
            directionalLight.shadow.camera.left = -100;
            directionalLight.shadow.camera.right = 100;
            directionalLight.shadow.camera.bottom = -100;

            // directionalLight.castShadow = true;
            // directionalLight.shadow.mapSize.width = 1024;
            // directionalLight.shadow.mapSize.height = 1024;
            // directionalLight.shadow.camera.left = -10;
            // directionalLight.shadow.camera.right = 10;
            // directionalLight.shadow.camera.top = -10;
            // directionalLight.shadow.camera.bottom = 10;
            // directionalLight.shadow.camera.near = 4;
            // directionalLight.shadow.camera.far = 20;
            // directionalLight.shadow.radius = 0.001;
            directionalLight.shadow.bias = -0.0005;


            // 设置直线光位置坐标
            directionalLight.position.set(15, 30, 20);
            // 设置直线光照投影
            // directionalLight.castShadow = true;
            // 添加直线光源到场景中
            scene.add(directionalLight);
            // 添加点光源
            let light = new THREE.PointLight(0xFFFFFF, 200, 400, 2);
            // 设置点光源位置坐标
            // light.position.set(30, 50, -50);
            light.position.set(100, 100, 100);
            light.penumbra = 0.5;
            // 设置点光源光照投影
            // light.castShadow = true;
            // 添加点光源到场景中
            scene.add(light);
            // 添加跟随相机的点光源
            // this.followLight = new THREE.PointLight(0xFFFFFF, 0, 30);

            // // this.followLight.castShadow = true;
            // // 添加跟随相机的点光源到场景中
            // scene.add(this.followLight);

        },
        // 初始化光源
        initLight2() {

            const directLight = new THREE.DirectionalLight('#ffffff', 0.5);

            const directLight1 = new THREE.DirectionalLight('#ffffff', 0.5);

            const directLight2 = new THREE.PointLight('#ffffff', 0.5);

            const ambientLight = new THREE.AmbientLight('#ffffff', 0.9);

            directLight.castShadow = true;

            directLight.position.set(15, 15, 15);

            directLight1.position.set(15, 15, 15);

            ambientLight.position.set(0, 0, 5);

            directLight2.position.set(15, 15, 15);

            directLight2.castShadow = true;

            scene.add(directLight, directLight1, ambientLight, directLight2);

        },
        //箱子障碍物
        boxMesh() {
            //添加障碍物
            //颜色贴图
            let mapBoxImg = this.textureLoader.load('./img/three/color.jpg')
            for (let i = 0; i <= 4; i++) {
                let boxGeometry = new THREE.BoxGeometry(4, 4, 4)
                let boxMaterial = new THREE.MeshStandardMaterial({
                    map: mapBoxImg
                })
                let boxMesh = new THREE.Mesh(boxGeometry, boxMaterial)
                let x = 4 * i - 8
                let y = i % 3 * 4 - 4
                let z = -i % 3 * 4 - 8
                boxMesh.position.set(x, y, z)
                //物体投射阴影
                boxMesh.castShadow = true
                //物体接收阴影
                boxMesh.receiveShadow = true
                this.objList.push(boxMesh)
                scene.add(boxMesh)
            }

            //添加一个长方体
            let cuboidGeometry = new THREE.BoxGeometry(15, 8, 6)
            let cuboidMaterial = new THREE.MeshStandardMaterial({
                map: mapBoxImg
            })
            let cuboidMesh = new THREE.Mesh(cuboidGeometry, cuboidMaterial);
            //物体投射阴影
            cuboidMesh.castShadow = true
            //物体接收阴影
            cuboidMesh.receiveShadow = true
            cuboidMesh.position.set(30, -4, 20)
            scene.add(cuboidMesh)
            this.objList.push(cuboidMesh)
        },
        // 初始化工厂模型
        initObj() {
            // 获取vue对象
            let _this = this;
            // 初始化模型加载器
            this.objLoader = new OBJLoader();
            const mtlLoader = new MTLLoader();
            mtlLoader.load('./obj/warehouse.mtl', function (materials) {
                materials.preload()
                _this.objLoader.setMaterials(materials)
                // 加载模型
                _this.objLoader.load('./obj/warehouse.obj',
                    // 加载完成回调函数,当模型加载完成后会调用该函数
                    function (obj) {

                        // obj.receiveShadow = true;      // 这种方式没有效果，只适用于 geometry 模型
                        // obj.castShadow = true;

                        obj?.children.forEach(child => {
                            // 设置每个子对象的 castShadow 属性为 true
                            // child.castShadow = true;
                            // child.receiveShadow = true;
                        });

                        obj.position.set(0, -6, 0);
                        // 缩放模型
                        obj.scale.set(.03, .03, .03);//.3
                        // 添加模型到碰撞检测列表
                        _this.objList.push(obj);
                        // 将模型添加到场景中
                        _scene.add(obj);

                    },
                    // 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                    function (xhr) {
                        let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                        _this.loadProgress = progress;
                    },
                    // 加载出错的回调函数
                    function (err) {

                        console.error('模型加载出错!');

                    }
                );
            },// 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                function (xhr) {
                    let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                    _this.loadProgress = progress == 100 ? _this.loadProgress : progress;
                });

        },
        // 初始化工厂模型
        initObj2() {
            // 获取vue对象
            let _this = this;
            // 初始化模型加载器
            this.objLoader = new OBJLoader();
            const mtlLoader = new MTLLoader();
            mtlLoader.load('./obj/ZhuTuZaiXian.mtl', function (materials) {
                materials.preload()
                // _this.objLoader.setMaterials(materials)
                // 加载模型
                _this.objLoader.load('./obj/ZhuTuZaiXian.obj',
                    // 加载完成回调函数,当模型加载完成后会调用该函数
                    function (obj) {

                        // obj.receiveShadow = true;      // 这种方式没有效果，只适用于 geometry 模型
                        // obj.castShadow = true;

                        obj?.children.forEach(child => {
                            // 设置每个子对象的 castShadow 属性为 true
                            // child.castShadow = true;
                            // child.receiveShadow = true;
                        });

                        obj.position.set(0, -7, 0);
                        // 缩放模型
                        obj.scale.set(.1, .1, .1);//.3
                        // 添加模型到碰撞检测列表
                        _this.objList.push(obj);
                        // 将模型添加到场景中
                        _scene.add(obj);

                    },
                    // 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                    function (xhr) {
                        let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                        _this.loadProgress = progress;
                    },
                    // 加载出错的回调函数
                    function (err) {

                        console.error('模型加载出错!');

                    }
                );
            },// 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                function (xhr) {
                    let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                    _this.loadProgress = progress == 100 ? _this.loadProgress : progress;
                });

        },
        // 初始化工厂模型
        initObj3() {
            // 获取vue对象
            let _this = this;

            const loader = new GLTFLoader();
            const dracoloader = new DRACOLoader()
            dracoloader.setDecoderPath('./obj/draco/')
            loader.setDRACOLoader(dracoloader)
            // https://cdn.bosscaigou.com/threejs/object/model.glb
            // https://cdn.bosscaigou.com/threejs/object/CheJian.glb
            // https://cdn.bosscaigou.com/threejs/object/YunHeTang.glb
            // https://cdn.bosscaigou.com/threejs/object/%E6%97%A0%E6%A0%87%E9%A2%98.glb
            // https://cdn.bosscaigou.com/threejs/object/BaoMaoJi.glb
            // https://cdn.bosscaigou.com/threejs/object/YunHeTang.glb
            loader.load('./obj/modelbbb.glb', (gltf) => {
                console.log(gltf)
                const car = gltf.scene;
                car.scale.set(0.6, 0.6, 0.6);
                car.position.set(0, 1, 0);
                _this.objList.push(car);
                _this.compDiv = document.getElementById('cssTag');
                _this.compTag = new CSS3DSprite(_this.compDiv);
                _this.compTag.position.set(0, 3.6, 0);
                _this.createPointerLine(new THREE.Vector3(0, 8.5, 0), new THREE.Vector3(0, 6, 0), '#FA005D', 0.03, '#FA005D');
                _this.compTag.scale.set(0.1 / 7, 0.1 / 7, 0.1 / 7) // 对标签进行缩放
                car.add(_this.compTag);
                car.layers.set(1);
                this.initClickContainer("A栋", car, true);
                scene.add(car);

            }, (progressEvent) => {
                const { loaded, total, lengthComputable } = progressEvent
                //lengthComputable  true 可以获取到当前文件的总字节大小
                if (lengthComputable) {
                    _this.loadProgress = Math.floor(loaded / total * 100)
                } else {
                    console.log('已加载' + loaded + '字节')
                }
            })

            return;

            // 初始化模型加载器
            this.objLoader = new OBJLoader();

            const mtlLoader = new MTLLoader();

            // 建筑面材质
            let buildMaterial = new THREE.MeshBasicMaterial({
                color: "#009EFF",     // 颜色
                transparent: true,    // 是否开启使用透明度
                opacity: 0.25,        // 透明度
                depthWrite: false,    // 关闭深度写入 透视效果
                side: THREE.DoubleSide, // 双面显示
            });

            // 建筑线材质
            let lineMaterial = new THREE.LineBasicMaterial({
                color: "#36BCFF",
                transparent: true,
                opacity: 0.4,
                depthWrite: false,
                side: THREE.DoubleSide,
            });

            mtlLoader.load('./obj/hgj.mtl', function (materials) {
                materials.preload()
                // _this.objLoader.setMaterials(materials)
                // 加载模型
                _this.objLoader.load('./obj/hgj.obj',
                    // 加载完成回调函数,当模型加载完成后会调用该函数
                    function (obj) {

                        // splineHelperObjects.push(obj);

                        // obj.traverse((e) => {              // 遍历模型中所有mesh
                        //     e.material = buildMaterial;             // 赋模型材质
                        //     if (e.geometry) {
                        //         const edges = new THREE.EdgesGeometry(
                        //             e.geometry
                        //         );
                        //         const line = new THREE.LineSegments(
                        //             edges,
                        //             lineMaterial                      // 赋线条材质
                        //         );
                        //         obj.add(line);                     // 把每一个mesh生成的线条添加到场景中
                        //     }
                        // });

                        // obj.receiveShadow = true;      // 这种方式没有效果，只适用于 geometry 模型
                        // obj.castShadow = true;

                        obj?.children.forEach(child => {
                            // 设置每个子对象的 castShadow 属性为 true
                            // child.castShadow = true;
                            // child.receiveShadow = true;

                            // console.log(obj.isMesh());

                        });

                        obj.position.set(-4, .9, .3);
                        // 缩放模型
                        obj.scale.set(.01, .01, .01);//.3
                        // 添加模型到碰撞检测列表
                        _this.objList.push(obj);
                        // 将模型添加到场景中
                        scene.add(obj);

                    },
                    // 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                    function (xhr) {
                        let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                        _this.loadProgress = progress;
                    },
                    // 加载出错的回调函数
                    function (err) {

                        console.error('模型加载出错!');

                    }
                );
            },// 正在加载模型时的回调函数,进度条代码，可以在此编写代码，传入 xhr.loaded 和 xhr.total，计算出加载进度
                function (xhr) {
                    let progress = Math.floor((xhr.loaded / xhr.total) * 100);
                    _this.loadProgress = progress == 100 ? _this.loadProgress : progress;
                });

        },
        // 初始化工厂模型
        initObj4(path) {
            // 获取vue对象
            let _this = this;
            const loader = new GLTFLoader();
            const dracoloader = new DRACOLoader()
            dracoloader.setDecoderPath('./obj/draco/')
            loader.setDRACOLoader(dracoloader)
            // https://cdn.bosscaigou.com/threejs/object/model.glb
            // https://cdn.bosscaigou.com/threejs/object/CheJian.glb
            // https://cdn.bosscaigou.com/threejs/object/YunHeTang.glb
            // https://cdn.bosscaigou.com/threejs/object/%E6%97%A0%E6%A0%87%E9%A2%98.glb
            // https://cdn.bosscaigou.com/threejs/object/BaoMaoJi.glb
            // https://cdn.bosscaigou.com/threejs/object/YunHeTang.glb
            // https://cdn.bosscaigou.com/threejs/object/modelbbb.glb
            // https://cdn.bosscaigou.com/threejs/object/model33.glb
            let filePath = 'https://cdn.bosscaigou.com/threejs/object/iii.glb';//'./obj/model33.glb';
            // let fileSize = getFileSize(filePath);
            loader.load(filePath, (gltf) => {
                const car = gltf.scene;
                car.scale.set(3.5, 3.5, 3.5);
                car.position.set(0, -5, 0);
                _this.objList.push(car);
                car.traverse(function (child) {
                    if (child.isMesh) {

                        child.material.alphaTest = 0.5
                        // //模型阴影
                        child.castShadow = true;
                        // 调整金属度
                        child.material.metalness = 0.38;
                        // //模型自发光
                        // child.material.emissive = child.material.color;
                        child.material.emissiveMap = child.material.map;
                    }
                });
                // _this.compDiv = document.getElementById('cssTag');
                // _this.compTag = new CSS3DSprite(_this.compDiv);
                // _this.compTag.position.set(80, 8.6, -11);
                // _this.createPointerLine(new THREE.Vector3(280, 25.08, -38.435), new THREE.Vector3(280, 20, -38.435), '#FA005D', 0.03, '#FA005D');
                // _this.compTag.scale.set(.1 / 6, .1 / 6, .1 / 6) // 对标签进行缩放
                // car.add(_this.compTag);

                let clusterOf2D = [];

                this.tagInfo.forEach((item, index) => {

                    _this.compDiv = document.getElementById('cssTag' + index);

                    _this.compTag = new CSS3DSprite(_this.compDiv);

                    clusterOf2D.push(_this.compTag);

                    _this.compTag.position.set(item.pos.x, item.pos.z, item.pos.y);

                    _this.compTag.scale.set(.1 / 3, .1 / 3, .1 / 3) // 对标签进行缩放

                    car.add(_this.compTag);

                });

                // function raycasterCollsionDetect(wavesLabel) {
                //     const labelPosition = wavesLabel.position.clone()
                //     //计算出标签和相机之前的距离(需要看备份一下坐标，不然执行下面转换NDC坐标后，计算会不准)
                //     const labelDistance = labelPosition.distanceTo(camera.position)
                //     //转换 向量到从世界空间投影到相机的标准化坐标（NDC）
                //     labelPosition.project(camera)
                //     raycaster.setFromCamera(labelPosition, camera)
                //     let model = scene?.children.filter(v => {
                //         return v instanceof THREE.Group
                //     })
                //     //过滤出模型
                //     if (model.length == 1) {
                //         const intersects = raycaster.intersectObjects(model[0].children)
                //         if (intersects.length == 0) {
                //             // 如果标签没有被model遮挡，应该全部显示
                //             wavesLabel.element.classList.add('codics-threejs-show-css-label-hidden')
                //         } else {
                //             const minDistance = intersects[0].distance
                //             if (minDistance < labelDistance) {
                //                 // 如果模型到相机的距离小于标签到相机的距离，说明光线射线先经过模型
                //                 // 表面此时标签其实在模型的后面，应该被遮挡而看不见
                //                 wavesLabel.element.classList.remove('codics-threejs-show-css-label-hidden')
                //                 wavesLabel.element.classList.add('codics-threejs-show-css-label-show')
                //             } else {
                //                 wavesLabel.element.classList.add('codics-threejs-show-css-label-hidden')
                //                 wavesLabel.element.classList.remove('codics-threejs-show-css-label-show')
                //             }
                //         }
                //     }

                // }
                // // 创建一个视锥，用于检查点是否在模型的可见范围内
                // const frustum = new THREE.Frustum();
                // // 绑定鼠标事件，当用户移动视角后触发()
                // function bindRayShotEvent() {
                //     document.addEventListener('mouseup', () => {
                //         if (clusterOf2D.length > 0) {

                //             clusterOf2D.forEach((c) => {
                //                 // raycasterCollsionDetect(c)

                //                 console.log(c)

                //                 raycaster2.set(camera.position, c.position.clone().sub(camera.position).normalize());

                //                 const intersects = raycaster2.intersectObjects(car.children, true);

                //                 if (intersects.length > 0) {

                //                     const object = intersects[0].object;

                //                     console.log(object)

                //                     // _this.setStatus(object);
                //                 }

                //             })
                //         }
                //     })
                // }

                // bindRayShotEvent();

                scene.add(car);

            }, (progressEvent) => {
                const { loaded, total, lengthComputable } = progressEvent
                //lengthComputable  true 可以获取到当前文件的总字节大小
                if (lengthComputable) {
                    _this.loadProgress = Math.floor(loaded / (total == 0 ? fileSize : total) * 100);
                } else {
                    console.log('已加载' + loaded + '字节')
                }
            })
        },
        async initWebObj4() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await modelInfo({ id: this.$route.params.id });

            loading.close();

            if (data.code == 200) {

                this.objData = data.data;

                if (data.data.pass != "") {

                    this.passDialog = true;

                } else this.initObj4(data.data.url);

                document.title = data.data.title;

                loadCss(`.codics-threejs-show-water-mark {
                     background: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='70%25' font-size='14' fill-opacity='0.3' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 100 100)'%3E` + data.data.wmark + `%3C/text%3E%3C/svg%3E") repeat;
                }`);

                if (data.data.bgm != "") {
                    // 播放背景音乐
                    this.mp3Play(data.data.bgm);
                }

            } else if (data.code == 407) {

                this.$message.error(data.message);

                this.applyError = true;

            } else {

                this.$message.error(data.message);

            }

        },
        initObj5() {
            // 获取vue对象
            let _this = this;
            const loader = new GLTFLoader();
            const dracoloader = new DRACOLoader()
            dracoloader.setDecoderPath('./obj/draco/')
            loader.setDRACOLoader(dracoloader)
            // 文件URL
            const fileUrl = 'https://cdn.bosscaigou.com/threejs/object/model555.zip';
            // 使用fetch加载文件
            fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    // 使用JSZip解压文件
                    JSZip.loadAsync(blob)
                        .then(zip => {
                            // 遍历zip中的所有文件
                            zip.forEach((relativePath, zipEntry) => {
                                // 如果想要解压为文件对象，可以这样做
                                if (!zipEntry.dir) {
                                    zipEntry.async('blob').then(blob => {
                                        // 创建一个Blob URL
                                        const filePath = URL.createObjectURL(blob);

                                        loader.load(filePath, (gltf) => {
                                            const car = gltf.scene;
                                            car.scale.set(3.5, 3.5, 3.5);
                                            car.position.set(0, -5, 0);
                                            _this.objList.push(car);
                                            car.traverse(function (child) {
                                                if (child.isMesh) {
                                                    // //模型阴影
                                                    child.castShadow = true;
                                                    // 调整金属度
                                                    child.material.metalness = 0.38;
                                                    // //模型自发光
                                                    // child.material.emissive = child.material.color;
                                                    child.material.emissiveMap = child.material.map;
                                                }
                                            });
                                            // _this.compDiv = document.getElementById('cssTag');
                                            // _this.compTag = new CSS3DSprite(_this.compDiv);
                                            // _this.compTag.position.set(80, 8.6, -11);
                                            // _this.createPointerLine(new THREE.Vector3(280, 25.08, -38.435), new THREE.Vector3(280, 20, -38.435), '#FA005D', 0.03, '#FA005D');
                                            // _this.compTag.scale.set(.1 / 6, .1 / 6, .1 / 6) // 对标签进行缩放
                                            // car.add(_this.compTag);


                                            _this.compDiv = document.getElementById('cssTag1');
                                            _this.compTag = new CSS3DSprite(_this.compDiv);
                                            _this.compTag.position.set(0, 8.6, 0);
                                            _this.compTag.scale.set(.1 / 3, .1 / 3, .1 / 3) // 对标签进行缩放
                                            car.add(_this.compTag);

                                            scene.add(car);

                                        }, (progressEvent) => {
                                            const { loaded, total, lengthComputable } = progressEvent
                                            //lengthComputable  true 可以获取到当前文件的总字节大小
                                            if (lengthComputable) {
                                                _this.loadProgress = Math.floor(loaded / (total == 0 ? fileSize : total) * 100);
                                            } else {
                                                console.log('已加载' + loaded + '字节')
                                            }
                                        })

                                    });
                                }
                            });
                        });
                })
                .catch(error => console.error('Error loading or unzipping file:', error));
        },
        // 初始化监听键盘事件
        handleKey() {
            // 按键抬起监听
            let keyUp = (e) => {
                switch (e.code) {
                    case "KeyW": //前
                    case "ArrowUp":
                        this.forward = false
                        break
                    case "KeyA": //左
                    case "ArrowLeft":
                        this.left = false
                        break
                    case "KeyD": //右
                    case "ArrowRight":
                        this.right = false
                        break
                    case "KeyS": //后
                    case "ArrowDown":
                        this.back = false
                        break
                    case "ShiftLeft": // 加速
                        this.accelerated = false
                        break
                }
            }
            // 按键按下监听
            let keyDown = (e) => {
                switch (e.code) {
                    case "KeyW": //前
                    case "ArrowUp":
                        this.forward = true
                        break
                    case "KeyA": //左
                    case "ArrowLeft":
                        this.left = true
                        break
                    case "KeyD": //右
                    case "ArrowRight":
                        this.right = true
                        break
                    case "KeyS": //后
                    case "ArrowDown":
                        this.back = true
                        break
                    case "ShiftLeft": //加速
                        this.accelerated = true
                        break
                    case "Space": // 跳
                        if (this.canJump) {
                            this.velocity.y += 30
                        }
                        this.canJump = false
                        break
                }
            }
            // 绑定按键抬起事件
            document.addEventListener("keyup", keyUp, false);

            document.addEventListener("keydown", keyDown, false);

        },
        //根据传入角度判断附近是否有障碍物
        //移动是根据按键决定的 在按下左键的时候进行左侧检测 右侧就右侧检测 利用射线判断有没有与物体相交 如果撞到物体上了就阻止这一侧的移动
        collideCheck(angle) {
            let rotationMatrix = new THREE.Matrix4()
            rotationMatrix.makeRotationY(angle * Math.PI / 180)
            const cameraDirection = controls.getDirection(new THREE.Vector3(0, 0, 0)).clone()
            cameraDirection.applyMatrix4(rotationMatrix)
            const raycaster = new THREE.Raycaster(controls.getObject().position.clone(), cameraDirection, 0, 2)
            raycaster.ray.origin.y -= 4
            const intersections = raycaster.intersectObjects(this.objList, true)
            return intersections.length;
        },
        // 渲染
        render() {

            if (this.sysMode == 0) {
                // 本次渲染时间
                let time = performance.now();
                // 计算时间差,单位秒
                let delta = (time - this.prevTime) / 1000;
                /**
                 * 移动逻辑
                 * 向前向后移动: +1 || -1
                 * 向左向右移动: -1 || +1
                 */
                /**
                 * 跳跃逻辑
                 * 执行每一帧动画时控制器都下落 当控制器高度小于起始高度时 还原
                 * 按下空格时给一个上升的y高度  每一帧动画都会递减
                 */
                if (controls.isLocked) {
                    // 相机x坐标
                    this.velocity.x = 0;
                    // 下降速度
                    this.velocity.y -= 70 * delta;
                    // 相机z坐标
                    this.velocity.z = 0;
                    // 获取相机位置
                    let position = controls.getObject().position;
                    //设置射线原点
                    raycaster.ray.origin.copy(position);

                    raycaster.ray.origin.y -= 4;
                    // 检测所有相交的物体
                    let intersects = raycaster.intersectObjects(this.objList, false);
                    // 自带的方法判断需要过滤
                    if (intersects.length) {

                        this.velocity.y = Math.max(0, this.velocity.y);

                        this.canJump = true;

                    }

                    if (this.forward || this.back) {
                        //向前才可加速
                        this.velocity.z = (Number(this.forward) - Number(this.back)) * this.speed + (this.forward ? Number(this.accelerated) * 0.5 : 0);

                    }

                    if (this.left || this.right) {

                        this.velocity.x = (Number(this.right) - Number(this.left)) * this.speed + Number(this.accelerated) * 0.5;

                    }
                    // // 四个方位是否产生碰撞
                    // let leftCollide = false;

                    // let rightCollide = false;

                    // let forwardCollide = false;

                    // let backCollide = false;
                    // // 碰撞检测 collide check
                    // if (this.forward) forwardCollide = this.collideCheck(0);

                    // if (this.back) backCollide = this.collideCheck(180);

                    // if (this.left) leftCollide = this.collideCheck(90);

                    // if (this.right) rightCollide = this.collideCheck(270);
                    // // 右侧有障碍物时向右移动 置零
                    // if ((this.right && rightCollide) || (this.left && leftCollide)) {

                    //     this.velocity.x = 0;

                    // }
                    // // 前方有障碍物时向前移动 置零
                    // if ((this.forward && forwardCollide) || (this.back && backCollide)) {

                    //     this.velocity.z = 0;

                    // }
                    // 设置控制器移动
                    controls.moveRight(this.velocity.x);

                    controls.moveForward(this.velocity.z);

                    controls.getObject().position.y += this.velocity.y * delta;
                    // 还原起始高度
                    if (controls.getObject().position.y < 0) {

                        this.velocity.y = 0;

                        controls.getObject().position.y = 0;

                        this.canJump = true;

                    }
                    // 设置光源大小及跟随相机移动
                    // this.followLight.intensity = 2;

                    // this.followLight.position.set(...position)

                }
                // 渲染内容
                renderer.render(scene, camera);
                // 上次渲染时间
                this.prevTime = time;

            } else if (this.sysMode == 1) {

                // requestAnimationFrame(this.render2.bind(this));
                controls.update();
                // 渲染器清除颜色、深度或模板缓存. 此方法将颜色缓存初始化为当前颜色
                renderer.clear();
                // if (this.rotateState) {
                //     const timer = Date.now() * 0.0003;
                //     camera.position.x = Math.sin(timer) * 0.5;
                //     camera.position.z = Math.cos(timer) * 0.5;
                //     // camera.lookAt(0, 0, 0);
                // }



                /*
    
                raycaster.setFromCamera(pointer, camera);
    
                const intersects = raycaster.intersectObjects(this.objList, true);
    
                if (intersects.length > 0) {
    
                    const object = intersects[0].object;
    
                    if (this.selectObject != object) {
    
                        this.setStatus(object);
    
                    }
    
                } else {
    
                    this.restoreObjectStatus();
    
                }
    
                */

                TWEEN.update();

                cssRenderer && cssRenderer.render(scene, camera);

                // this.effectComposer && this.effectComposer.render();

                renderer.clearDepth();

                this.updateOrientation && this.updateOrientation();

                renderer && renderer.render(scene, camera);

            } else if (this.sysMode == 2) {
                // 更新地图控制器状态
                controls.update();

                TWEEN.update();
                // 渲染场景
                renderer.render(scene, camera);

            } else if (this.sysMode == 3) {


                // 更新控制器
                controls.update(0.1);

                TWEEN.update();
                // 渲染场景
                renderer.render(scene, camera);

            }

        }
    }
}
</script>

<style>
/* THREE容器 */
.codics-threejs-show-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden !important;
    box-sizing: border-box;
    /* background: url('https://cdn.bosscaigou.com/threejs/imgs/%E8%83%8C%E6%99%AF.png') center no-repeat !important; */
    /* background: #9acdff !important; */
    background-size: cover;
}

/* 操作提示 */
.codics-threejs-show-desc {
    position: absolute;
    left: 0;
    top: 50px;
    /* color: rgb(255, 72, 0); */
    color: #4868FE;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, .3);
    opacity: .5;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: .5s;
    /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
}

.codics-threejs-show-frame {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, .25);
    backdrop-filter: blur(5px);
    transition: .5s;
}

.codics-threejs-show-frame-btn {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-threejs-show-frame-progress {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-threejs-show-top-img {
    height: 55px;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    left: 0;
    right: 0;
    transition: .5s;
}

.codics-threejs-show-top-title {
    height: 55px;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    line-height: 55px;
    text-align: center;
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 5px;
    user-select: none;
    opacity: .9;
    transition: .5s;
}

.codics-threejs-show-bck-img {
    height: 100%;
    width: 100%;
    position: absolute !important;
    margin: auto;
    left: 0;
    top: 0;
    pointer-events: none !important;
}

.codics-threejs-show-side-img {
    height: 90%;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    top: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
    box-sizing: border-box !important;
    transition: .5s;
}

.codics-threejs-show-content-img {
    height: 80%;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 50px;
    bottom: 0;
    user-select: none;
    pointer-events: none;
    transition: .5s;
}

.codics-threejs-show-bottom-img {
    height: fit-content;
    width: calc(40vw - 50px);
    position: absolute !important;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0px;
    user-select: none;
    pointer-events: none;
}

.codics-threejs-show-bottom-btn {
    height: 25px;
    width: fit-content;
    position: absolute !important;
    margin: auto;
    bottom: 18px;
    left: 0;
    right: 0;
    box-sizing: border-box;
    transition: .3s;
    cursor: pointer;
    overflow: hidden;
}

.codics-threejs-show-bottom-btn:hover {
    filter: drop-shadow(0 0 3px rgba(255, 198, 35, .5)) !important;
}

.codics-threejs-show-bottom-btn::after {
    content: attr(data-content);
    line-height: 25px;
    width: 100%;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    color: #FFF;
    font-weight: 400;
    pointer-events: none;
}

.codics-threejs-show-bottom-btn img {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    object-fit: fill;
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
    color: rgb(90, 250, 188);
}

.codics-threejs-show-body-apply-error {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
}

.codics-threejs-show-body-apply-error-img {
    height: 200px;
    width: 200px;
    position: absolute !important;
    margin: auto;
    top: -300px;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-threejs-show-body-apply-error-txt {
    height: fit-content;
    width: 80%;
    max-width: 400px;
    position: absolute;
    margin: auto;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.codics-threejs-show-body-apply-error-btn {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 340px;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>