<template>
    <div class="codics-factory-amdin-hearder">
        <div class="codics-factory-amdin-hearder-logo-box" @click="changeCollapse"
            :style="{ width: isCollapse ? '46px' : '251px' }">
            <el-image class="codics-factory-amdin-hearder-logo"
                src="https://cdn.bosscaigou.com/huayuan/img/yasuo_icon.webp" fit="fill"></el-image>
            <div class="codics-factory-amdin-hearder-logo-txt"
                :style="{ 'visibility': isCollapse ? 'hidden' : 'visible', 'opacity': isCollapse ? '0' : '1' }">
                邦邦工厂</div>
            <div class="codics-factory-amdin-hearder-icon iconfont"
                :style="{ 'opacity': isCollapse ? '0' : '1', 'position': 'absolute', 'top': '0 !important', 'right': '0 !important' }">
                &#xe79a;
            </div>
        </div>
        <el-breadcrumb class="codics-factory-amdin-hearder-breadcrumb" separator="/">
            <el-breadcrumb-item v-for="(item, index) in breadList" v-show="item.name != ''" :key="index"
                :to="index == breadList.length - 1 ? '' : item.path">{{ item.name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="codics-factory-amdin-hearder-account-box">
            <el-avatar class="codics-factory-amdin-hearder-account-himg" :size="29"
                :src="(userInfo == null || userInfo.user.himg != null) ? ('https://cdn.bosscaigou.com/' + (userInfo == null ? '' : userInfo.user.himg)) : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
            <div class="codics-factory-amdin-hearder-account-nick">{{ userInfo != null ? userInfo.user.nickname : '未知用户'
                }}</div>
            <div class="codics-factory-amdin-hearder-icon iconfont"
                style="margin-left: 0 !important;font-size: 20px !important;">&#xe83d;</div>
            <div class="codics-factory-amdin-hearder-account-box-menu-box">
                <div class="codics-factory-amdin-hearder-account-box-menu-item"
                    v-for="(item, index) in ((userInfo == null || userInfo.user.member != 0) ? ['退出登录'] : ['管理员后台', '模型列表', '退出登录'])"
                    :key="index" @click="handleMenuClick(item, index)">
                    {{ item }}</div>
            </div>
        </div>
        <el-button style="position:relative;float:right;margin-right:10px;margin-top: 5px;" size="small" type="warning"
            @click="addWxFuc">添加管理微信</el-button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { jumpTo, copyTxt } from '@/utils/myutils';
import { getCookie } from '@/utils/mycookie';
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: false,
            messCount: 0
        }
    },
    data() {
        return {
            breadList: [],
            warnInfo: '',
            nowUrl: ""
        }
    },
    mounted() {
        this.breadList = this.$route.matched;
        this.nowUrl = window.location.href;
    },
    methods: {
        addWxFuc() {
            this.$alert('管理员微信：18661925657，点击复制按钮复制到剪切板！', '添加管理员微信', {
                confirmButtonText: '复制',
                callback: action => {
                    this.$message({
                        message: '管理员微信号复制成功啦！请打开微信添加！',
                        type: 'success'
                    });
                    copyTxt("18661925657");
                }
            });
        },
        toPage(n, p, q) {
            jumpTo(this, n, p, q);
        },
        changeCollapse() {
            this.$emit("changeCollapse");
        },
        handleMenuClick(itm, idx) {

            switch (itm) {

                case '管理员后台':

                    jumpTo(this, "后台首页", {}, {});

                    break;

                case '模型列表':

                    jumpTo(this, "预览列表", {}, {});

                    break;

                case '退出登录':

                    this.$store.dispatch('logout');

                    jumpTo(this, "工厂主页", { 'url': 'admin' }, {});

                    break;

            }

            console.log(itm, idx)

        },
        refresh(data) {

        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        $route(to, from) {
            this.breadList = this.$route.matched;
        }
    }
}
</script>

<style>
.codics-factory-amdin-hearder {
    height: 45px;
    width: 100%;
    position: relative;
    float: left;
    /* border-bottom: 1px solid #F5F5F5; */
    background: rgba(255, 255, 255, .05);
    backdrop-filter: blur(5px);
    z-index: 22;
}

.codics-factory-amdin-hearder-logo-box {
    height: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    /* border-right: 1px solid #F5F5F5; */
    transition: .3s;
    overflow: hidden;
    cursor: pointer;
}

.codics-factory-amdin-hearder-logo {
    height: 100%;
    width: 45px;
    position: relative;
    float: left;
    padding: 9px !important;
    user-select: none !important;
    box-sizing: border-box;
    /* left: -500px;
    filter: drop-shadow(500px 0px 0px #333); */
}

.codics-factory-amdin-hearder-logo-txt {
    height: 45px;
    width: fit-content;
    position: relative;
    float: left;
    margin-left: 0px;
    line-height: 47px;
    color: #ffb108;
    font-size: 15px;
    user-select: none !important;
    transition: .2s;
}

.codics-factory-amdin-hearder-icon {
    height: 100%;
    width: 45px;
    position: relative;
    float: left;
    margin-left: 81px;
    line-height: 45px;
    color: #333;
    font-size: 16px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: .4s;
}

.codics-factory-amdin-hearder-account-box {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    box-sizing: border-box;
    cursor: pointer;
}

.codics-factory-amdin-hearder-account-himg {
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
}

.codics-factory-amdin-hearder-account-nick {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 45px;
    text-align: center;
    color: #444;
    font-size: 13px;
    font-weight: bolder;
    margin-left: 15px;
    user-select: none;
}

.codics-factory-amdin-hearder-icon2 {
    height: 100%;
    width: 100%;
    position: relative;
    float: right;
    line-height: 25px;
    color: #444;
    font-size: 16px;
    text-align: center;
    user-select: none;
    cursor: pointer;
}

.codics-factory-amdin-hearder-icon-tip {
    top: 5px !important;
    background: #FFEDED !important;
    border: 1px solid #FFF !important;
}

.codics-factory-amdin-hearder-icon-tip div {
    top: 10px !important;
    border-left-color: #FFF !important;
}

.codics-factory-amdin-hearder-icon-tip div::after {
    border-left-color: #FFEDED !important;
}

.codics-factory-amdin-hearder-breadcrumb {
    line-height: 50px;
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 15px;
}

.codics-factory-amdin-hearder-breadcrumb .el-breadcrumb {
    font-size: 12px !important;
}

.codics-factory-amdin-hearder-breadcrumb .el-breadcrumb__inner {
    font-size: 13px !important;
}

.codics-factory-amdin-hearder-account-box:hover .codics-factory-amdin-hearder-account-box-menu-box {
    opacity: 1;
    visibility: visible;
}

.codics-factory-amdin-hearder-account-box-menu-box {
    height: fit-content;
    width: 128px;
    position: absolute;
    top: 55px;
    right: 10px;
    z-index: 11;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #EEE;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .1);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.codics-factory-amdin-hearder-account-box-menu-item {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 40px;
    font-size: 12px;
    color: #555;
    font-weight: bolder;
    text-align: center;
    transition: .3s;
}

.codics-factory-amdin-hearder-account-box-menu-item:hover {
    background: rgba(0, 0, 0, .06);
}

.codics-factory-amdin-hearder-icon2-box {
    height: 25px;
    width: 25px;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}
</style>